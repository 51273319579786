.entrance-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: background-color 1000ms linear, height 1000ms ease-in-out;
}

.colors-btn {
  position: fixed;
  border-radius: 100px;
  padding: 10px 20px 10px 20px;
  border-style: none;
  background-color: white;
  cursor: pointer;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  right: 50;
  bottom: 50;
}
.colors-btn:hover {
  background-color: #f5f5f5;
}
.colors-btn__text {
  font-family: Rubik, Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

.entrance-box {
  margin-top: 120px;
  padding-left: 25px;
  padding-right: 25px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  transition: background-color 1000ms linear;
  padding-top: 30px;
  transition: all 400ms linear;
}


.entrance-box__header {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 36px;
}
.entrance-box__header__option:first-child {
  margin-right: 19px;
}
.entrance-box__header__option {
  font-family: 'Karla', 'Roboto', sans-serif;
  font-size: 1.4rem;
  color: rgba(33, 37, 41, 0.25);
  user-select: none;
}
.entrance-box__header__option a {
  color: inherit;
  text-decoration: inherit;
}

.password-eye {
  border: none;
  background: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 25;
}
.password-eye__icon {
  pointer-events: none;
}

.form-title {
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 3.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-control {
  border-radius: 100px;
  border: 1px solid rgba(0, 20, 94, 0.30);
  height: 60px;
  padding-left: 25px;
}
.form-control.form-control--currency {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='gray' font-size='1rem' font-family='Karla'>€</text></svg>");
  height: 50px;
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 12px;
}

.form-control.form-control--error {
  border-color: #FF0000 !important;
}

.form__label__text.form__label__text-error {
  color: #FF0000;
}
.form__label__error-message {
  position: absolute;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
  color: white;
  background-color: #FF0000;
  border-radius: 10px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  bottom: -10;
  right: 10;
}

.form-control:focus {
  border: 2px solid var(--color2);
  outline: none;
}
.form-group {
  margin-bottom: 31px;
  width: 100%;
  position: relative;
}
.from-group--hidden {
  display: none;
}

.form-button {
  margin-top: 9px;
  border: none;
  border-radius: 100px;
  width: 100%;
  height: 65px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2rem;
  color: white;
  font-weight: 700;
  cursor: pointer;
}
.form-button:disabled {
  cursor: not-allowed;
}
.form-button--loading {
  animation: background-color 2s infinite;
}
.form-button--disabled {
  background-color: rgb(175, 175, 175) !important;
  cursor: not-allowed !important;
}

@keyframes background-color {
  0% {
    background-color: var(--color2);
  }
  50% {
    background-color: var(--color1);
  }
  100% {
    background-color: var(--color2);
  }

}

.form-button:hover {
  background-color: var(--color1) !important;
  transition: background-color 200ms linear;
}

.form__label {
  font-family: 'Karla', 'Roboto', sans-serif;
  font-size: 1.8rem;
  color: rgba(33, 37, 41, 0.50);
  margin-bottom: 5px;
}

.form__label input {
  font-family: 'Karla', 'Roboto', sans-serif;
  color: #212529;
  font-size: 1.8rem;
  width: 100%;
}
.form__label__text {
  position: absolute;
  top: -12;
  left: 25;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  user-select: none; /* Standard */
}

.youdrop-char {
  width: calc(221px + 5vw);
  height: calc(230px + 5vw);
  margin-bottom: 30px;
}

.entrance-title {
  font-family: Rubik, Roboto, sans-serif;
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 3rem;
  max-width: 27.9rem;
  color:#FFFFFF;
}

.entrance-description {
  font-family: Karla, Roboto, sans-serif;
  display: inline-block;
  font-size: 2rem;
  color:#FFFFFF;
  max-width: 45.0rem;
}

.entrance-left-col {
  display: none;
}

.form-checkboxes {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.form__checkbox__label {
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
  color: rgba(33, 37, 41, 0.55);
  margin-bottom: 10px;
}

.form__checkbox__label.form__checkbox__label--error {
  border-bottom: 1px solid #FF0000 !important;
}

.form__checkbox-text {
  margin-left: 0.7rem;
}
.form__checkbox {
  margin-right: 7px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.form-button.form-button--50w {
  width: 50%;
}
.form-button.form-button--secondary {
  font-weight: 400;
  background-color: white !important;
  color: var(--color2);
  border: 2px solid var(--color2);
  text-transform: none;
}
@media screen and (min-width: 43rem /* tablets 688px */) {
  .entrance-box {
    width: 492px;
    height: auto;
    padding-left: 55px;
    padding-right: 55px;
    min-height: 618px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 54px;
  }
  .form-control {
    width: 372px;
  }
  .form-title {
    margin-bottom: 5rem;
  }
  .entrance-box__header {
    margin-bottom: 62px;
  }
  .form-group {
    margin-bottom: 26px;
  }
  .entrance-box--minheight {
    min-height: 634px;
  }
}


@media screen and (min-width: 82rem /* desktop-wide 1312px */) {
  .entrance-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .entrance-box {
    margin-top: 0px;
  }
  .entrance-left-col {
    align-self: flex-start;
    padding-top: calc(100px + 5%);
    display: block;
  }
  .entrance-container {
    padding-left: 132px;
    padding-right: 132px;
  }
  .colors-btn {
    right: 120;
    bottom: 120;
  }
}

@media screen and (min-width: 100rem /* desktop-wide 1600px */) {
  .entrance-container {
    padding-left: 200px;
    padding-right: 200px;
  }
  .entrance-title {
    max-width: 50.9rem;
  }
  .entrance-description {
    max-width: 60.0rem;
  }
  .youdrop-char {
    margin-bottom: 60px;
  }

}
