.colors-title {
  font-size: 3rem;
  margin-top: 3.5rem;
  color: #212529;
  text-align: center;
  font-family: 'Rubik', 'Roboto', sans-serif;
  font-weight: 700;
  margin-bottom: 9.3rem;
}

.colors-title--white {
  color: #fff;
  margin-bottom: 2rem;
}

.colors-description {
  font-family: 'Karla', 'Roboto', sans-serif;
  font-size: 1.6rem;
  color: white;
  margin-bottom: 5.5rem;
  text-align: center;
  width: 32.5rem;
}

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: background-color 1000ms linear;
  /* Padding with percentage to make it compatible with multiple Mobile Widths, showing the colors title properly*/
  padding-left: 13%;
  padding-right: 13%;
}

.colors {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 35px;
  grid-row-gap: 30px;
  align-items: center;
  justify-content: center;
}

.color-image__container {
  min-height: 150px;
  max-height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.color-image {
  width: auto;
  max-height: 152px;
  max-width: 200px;
}

.colors-button {
  font-family: 'Rubik', 'Roboto', sans-serif;
  background-color: white;
  font-weight: 800;
  margin-top: 50px;
  width: 22rem;
  height: 6.5rem;
  border-radius: 100px;
  border: none;
  font-size: 2rem;
  text-transform: uppercase;
  /*box-shadow: inset 0 0 0 0 #FBDDD2;*/
  transform: scale(1);
  transition: transform 0.4s;
  cursor: pointer;
}

.colors-button:hover,
.colors-button:focus {
/*  box-shadow: inset 22rem 0 0 0 #FBDDD2;*/
  transform: scale(1.10);
}
.colors-button.colors-button--active {
  animation: smash 0.5s ease-in alternate infinite;
}

@keyframes smash {
  0% {
    transform: scale(1.10);
  }
  25% {
    transform: translateX(-2px) scale(1);
  }
  50% {
    transform: translateX(-1px) scale(1);
  }
  75% {
    transform: translateX(-2px) scale(1);
  }
  100% {
    transform: translateX(0px) scale(1.10);
  }
}


@media screen and (min-width: 43rem) {
  .color-image__container {
    min-height: 200px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .color-image {
    width: auto;
    max-height: 200px;
    max-width: 250px;
  }
  .colors-title {
    font-size: 3.6rem;
    margin-top: 4rem;
    width: 35.6rem;
  }
  .colors-button {
    margin-top: 229px;
  }
  .colors {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 15px;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .colors-description {
    width: 53rem;
    font-size: 2rem;
    margin-bottom: 6.7rem;
  }
}

@media screen and (min-width: 82rem) {
  .colors-button {
    position: absolute;
    bottom: 130;
    right: 120;
  }
}