.color-picker {
  border-radius: 50px;
  background-color: #55C8DC;
  width: 35px;
  height: 35px;
  border: none;
  animation: scaleUpDown .6s ease-in-out alternate 1s;
  transition: transform .2s, width 0.7s, height 0.7s;
  cursor: pointer;
}

.color-picker:hover {
  transform: scale(1.15);
}

.color-picker.color-picker--bigger {
  height: 35px;
  width: 35px;
  transform: scale(1) !important;
}

.color-picker--bordered {
  border: 4px solid #fff;
  height: 25px;
  width: 25px;
}
.color-picker--bordered:hover {
  transform: scale(1.40);
}

@keyframes scaleUpDown {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% { transform: scale(1); }
}

.color-picker__container {
  width: 40px;
  height: auto;
  justify-content: center;
  display: flex;
}

.color-picker__container.color-picker__container--less-width {
  width: 45px;
}

@media screen and (min-width: 62rem) {
  .color-picker__container {
    width: 60px;
  }
}
@media screen and (min-width: 82rem) {
  .color-picker__container {
    width: 55px;
  }
}