
.checkbox-container {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.checkbox--hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 5px;
  transition: all 150ms;
  border-color: rgba(33, 37, 41, 0.55);
  border-width: 1px;
  border-style: solid;
}


.checkbox__mark {
  list-style-type: none;
  font-size: 1.5rem;
  background-color: var(--color2);
  height: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox__mark::before {
  position: absolute; 
  content: '\2713';
  display: inline-block;
  color: white;
}