@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 300;
  src: local(Rubik Light Italic), local(Rubik-LightItalic), url("Rubik-LightItalic.f86ee188.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 300;
  src: local(Rubik Light Italic), local(Rubik-LightItalic), url("Rubik-LightItalic.f86ee188.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 300;
  src: local(Rubik Light Italic), local(Rubik-LightItalic), url("Rubik-LightItalic.f86ee188.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 300;
  src: local(Rubik Light Italic), local(Rubik-LightItalic), url("Rubik-LightItalic.f86ee188.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 400;
  src: local(Rubik Italic), local(Rubik-Italic), url("Rubik-Italic.47ea3602.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 400;
  src: local(Rubik Italic), local(Rubik-Italic), url("Rubik-Italic.47ea3602.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 400;
  src: local(Rubik Italic), local(Rubik-Italic), url("Rubik-Italic.47ea3602.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 400;
  src: local(Rubik Italic), local(Rubik-Italic), url("Rubik-Italic.47ea3602.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 500;
  src: local(Rubik Medium Italic), local(Rubik-MediumItalic), url("Rubik-MediumItalic.c0256f7f.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 500;
  src: local(Rubik Medium Italic), local(Rubik-MediumItalic), url("Rubik-MediumItalic.c0256f7f.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 500;
  src: local(Rubik Medium Italic), local(Rubik-MediumItalic), url("Rubik-MediumItalic.c0256f7f.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 500;
  src: local(Rubik Medium Italic), local(Rubik-MediumItalic), url("Rubik-MediumItalic.c0256f7f.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 700;
  src: local(Rubik Bold Italic), local(Rubik-BoldItalic), url("Rubik-BoldItalic.0dc99455.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 700;
  src: local(Rubik Bold Italic), local(Rubik-BoldItalic), url("Rubik-BoldItalic.0dc99455.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 700;
  src: local(Rubik Bold Italic), local(Rubik-BoldItalic), url("Rubik-BoldItalic.0dc99455.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 700;
  src: local(Rubik Bold Italic), local(Rubik-BoldItalic), url("Rubik-BoldItalic.0dc99455.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 900;
  src: local(Rubik Black Italic), local(Rubik-BlackItalic), url("Rubik-BlackItalic.0058df95.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 900;
  src: local(Rubik Black Italic), local(Rubik-BlackItalic), url("Rubik-BlackItalic.0058df95.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 900;
  src: local(Rubik Black Italic), local(Rubik-BlackItalic), url("Rubik-BlackItalic.0058df95.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 900;
  src: local(Rubik Black Italic), local(Rubik-BlackItalic), url("Rubik-BlackItalic.0058df95.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  src: local(Rubik Light), local(Rubik-Light), url("Rubik-Light.789073f8.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  src: local(Rubik Light), local(Rubik-Light), url("Rubik-Light.789073f8.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  src: local(Rubik Light), local(Rubik-Light), url("Rubik-Light.789073f8.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  src: local(Rubik Light), local(Rubik-Light), url("Rubik-Light.789073f8.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: local(Rubik), local(Rubik-Regular), url("Rubik-Regular.10e3aea6.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: local(Rubik), local(Rubik-Regular), url("Rubik-Regular.10e3aea6.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: local(Rubik), local(Rubik-Regular), url("Rubik-Regular.10e3aea6.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: local(Rubik), local(Rubik-Regular), url("Rubik-Regular.10e3aea6.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src: local(Rubik Medium), local(Rubik-Medium), url("Rubik-Medium.1e6e1fb9.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src: local(Rubik Medium), local(Rubik-Medium), url("Rubik-Medium.1e6e1fb9.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src: local(Rubik Medium), local(Rubik-Medium), url("Rubik-Medium.1e6e1fb9.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src: local(Rubik Medium), local(Rubik-Medium), url("Rubik-Medium.1e6e1fb9.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  src: local(Rubik Bold), local(Rubik-Bold), url("Rubik-Bold.170b9490.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  src: local(Rubik Bold), local(Rubik-Bold), url("Rubik-Bold.170b9490.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  src: local(Rubik Bold), local(Rubik-Bold), url("Rubik-Bold.170b9490.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  src: local(Rubik Bold), local(Rubik-Bold), url("Rubik-Bold.170b9490.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  src: local(Rubik Black), local(Rubik-Black), url("Rubik-Black.1043f96b.woff2") format("truetype");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  src: local(Rubik Black), local(Rubik-Black), url("Rubik-Black.1043f96b.woff2") format("truetype");
  unicode-range: U+590-5FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  src: local(Rubik Black), local(Rubik-Black), url("Rubik-Black.1043f96b.woff2") format("truetype");
  unicode-range: U+100-24F, U+1E??, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  src: local(Rubik Black), local(Rubik-Black), url("Rubik-Black.1043f96b.woff2") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: Karla;
  font-style: normal;
  font-weight: 400;
  src: url("Karla-Regular.3cd26653.ttf") format("truetype");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Lato-Regular.ad872c70.ttf") format("truetype");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Lato-Regular.ad872c70.ttf") format("truetype");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body, html {
  min-height: 100vh;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.container-app {
  background-color: var(--color4);
  flex-direction: column;
  flex: auto;
  padding: 40px 25px 100px;
  font-size: 1.6rem;
  display: flex;
}

.youdrop-logo {
  width: 11.5rem;
  height: 11.8rem;
  background-image: url("logo.2cd15c46.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.youdrop-logo.youdrop-logo--white {
  width: 4.8rem;
  height: 5rem;
  filter: brightness(0) invert();
}

.youdrop-logo--top {
  width: 4.8rem;
  height: 5rem;
  position: absolute;
  top: 30px;
  left: 25px;
}

.flex-row {
  width: 100%;
  flex-direction: row;
  display: flex;
}

.flex-center {
  justify-content: center;
  display: flex;
}

.separator--15w {
  width: 15px;
}

input[type="checkbox"] {
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  background-color: #fff;
  border: .15em solid;
  border-radius: .15em;
  place-content: center;
  margin: 0;
  display: grid;
  transform: translateY(-.075em);
}

.loading-container {
  height: 100%;
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.lds-dual-ring {
  width: 80px;
  height: 80px;
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  width: 64px;
  height: 64px;
  border: 6px solid rgba(var(--rgbcolor), .3);
  border-color: rgba(var(--rgbcolor), .3) transparent rgba(var(--rgbcolor), .3) transparent;
  border-radius: 50%;
  margin: 8px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

input[type="checkbox"]:before {
  content: "";
  width: .65em;
  height: .65em;
  box-shadow: inset 1em 1em var(--form-control-color);
  transform-origin: 0 100%;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transition: transform .12s ease-in-out;
  transform: scale(0);
}

input[type="checkbox"]:checked:before {
  transform: scale(1);
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.title-h1 {
  color: #212529;
  letter-spacing: 0;
  margin-bottom: 20px;
  font: bold 2.8rem / 3.6rem Rubik;
}

.paragraph {
  color: #212529;
  font: 1.6rem / 2.1rem Karla;
  display: block;
}

.small-circles-wrapper {
  width: 40%;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.small-circle {
  height: 8px;
  width: 8px;
  background-color: #fff;
  border-radius: 100px;
  display: inline-block;
}

.small-circle--animation1 {
  animation: 1.2s .2s infinite small-circle-animation1;
}

.small-circle--animation2 {
  animation: 1.2s .4s infinite small-circle-animation1;
}

.small-circle--animation3 {
  animation: 1.2s .6s infinite small-circle-animation1;
}

@keyframes small-circle-animation1 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  50% {
    opacity: .4;
    transform: translateY(-4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.youdrop-logo.youdrop-logo--long-blue {
  background-image: url("logo_long_blue.43d87a69.svg");
  width: 11.5rem !important;
  height: 2.2rem !important;
}

.flex-section {
  flex-direction: column;
  display: flex;
}

.max-w-330 {
  max-width: 330px;
}

.file-upload__droparea {
  color: #212529;
  text-align: center;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 1px solid #00145e4d;
  border-radius: 10px;
  padding: 35px 0;
  font-family: Karla, Roboto, sans-serif;
  font-size: 2.2rem;
  position: relative;
}

.file-upload__droparea--dragging {
  border: 1px solid var(--color2);
  box-shadow: inset 0px 0px 0px 2px var(--color2);
}

.file-upload__droparea.active {
  border: 1px solid #0389b2;
  box-shadow: inset 0 0 0 2px #0389b2;
}

.file-upload__fileinput {
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.file-upload__droparea label {
  display: inline;
}

.file-upload__droparea label a {
  color: var(--color1);
  font-weight: 700;
  text-decoration: underline;
}

.file-upload__droparea span {
  color: #21252940;
  margin-top: 15px;
  font-size: 1.8rem;
  display: block;
}

@media screen and (width >= 62rem) {
  .container-app {
    padding-top: 60px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .flex-section {
    flex-direction: row;
    gap: 15px;
    display: flex;
  }

  .title-h1 {
    color: #212529;
    margin-bottom: 15px;
    font: bold 3.2rem / 4.2rem Rubik;
  }

  .paragraph {
    color: #212529;
    font: 1.6rem / 2.1rem Karla;
    display: block;
  }

  .youdrop-logo {
    width: 15rem;
    height: 15.2rem;
  }

  .youdrop-logo.youdrop-logo--top {
    top: 50px;
    left: 60px;
  }

  .youdrop-logo.youdrop-logo--long {
    width: 25.1rem;
    height: 5rem;
  }

  .youdrop-logo.youdrop-logo--white.youdrop-logo--long {
    background-image: url("logo_long.ec05d303.png");
  }

  .youdrop-logo.youdrop-logo--blue.youdrop-logo--long {
    background-image: url("logo_long_blue.43d87a69.svg");
  }
}

@media screen and (width >= 82rem) {
  .flex-section {
    flex-direction: row;
    gap: 25px;
    display: flex;
  }

  .max-w-330 {
    max-width: 450px;
  }

  .title-h1 {
    font: bold 3.2rem / 4.3rem Rubik;
  }

  .youdrop-logo.youdrop-logo--top {
    top: 50px;
    left: 120px;
  }

  .container-app {
    padding-left: 132px;
    padding-right: 132px;
  }
}

@media screen and (width >= 100rem) {
  .youdrop-logo.youdrop-logo--top {
    top: 50px;
    left: 200px;
  }

  .container-app {
    padding-left: 275px;
    padding-right: 275px;
  }
}

.entrance-container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 1s linear, height 1s ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.colors-btn {
  cursor: pointer;
  z-index: 1000;
  background-color: #fff;
  border-style: none;
  border-radius: 100px;
  padding: 10px 20px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  box-shadow: 0 2px 7px #00000026;
}

.colors-btn:hover {
  background-color: #f5f5f5;
}

.colors-btn__text {
  font-family: Rubik, Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

.entrance-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-direction: column;
  margin-top: 120px;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
  transition: all .4s linear;
  display: flex;
  position: relative;
  overflow: hidden;
}

.entrance-box__header {
  width: 100%;
  flex-direction: row;
  margin-bottom: 36px;
  display: flex;
}

.entrance-box__header__option:first-child {
  margin-right: 19px;
}

.entrance-box__header__option {
  color: #21252940;
  -webkit-user-select: none;
  user-select: none;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
}

.entrance-box__header__option a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.password-eye {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
}

.password-eye__icon {
  pointer-events: none;
}

.form-title {
  margin-bottom: 3.5rem;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
}

.form {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.form-control {
  height: 60px;
  border: 1px solid #00145e4d;
  border-radius: 100px;
  padding-left: 25px;
}

.form-control.form-control--currency {
  height: 50px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='gray' font-size='1rem' font-family='Karla'>€</text></svg>");
  background-position: 10px;
  background-repeat: no-repeat;
  padding-left: 12px;
}

.form-control.form-control--error {
  border-color: red !important;
}

.form__label__text.form__label__text-error {
  color: red;
}

.form__label__error-message {
  color: #fff;
  background-color: red;
  border-radius: 10px;
  padding: 5px 8px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
  position: absolute;
  bottom: -10px;
  right: 10px;
}

.form-control:focus {
  border: 2px solid var(--color2);
  outline: none;
}

.form-group {
  width: 100%;
  margin-bottom: 31px;
  position: relative;
}

.from-group--hidden {
  display: none;
}

.form-button {
  width: 100%;
  height: 65px;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  margin-top: 9px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

.form-button:disabled {
  cursor: not-allowed;
}

.form-button--loading {
  animation: 2s infinite background-color;
}

.form-button--disabled {
  cursor: not-allowed !important;
  background-color: #afafaf !important;
}

@keyframes background-color {
  0% {
    background-color: var(--color2);
  }

  50% {
    background-color: var(--color1);
  }

  100% {
    background-color: var(--color2);
  }
}

.form-button:hover {
  transition: background-color .2s linear;
  background-color: var(--color1) !important;
}

.form__label {
  color: #21252980;
  margin-bottom: 5px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
}

.form__label input {
  color: #212529;
  width: 100%;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
}

.form__label__text {
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  top: -12px;
  left: 25px;
}

.youdrop-char {
  width: calc(221px + 5vw);
  height: calc(230px + 5vw);
  margin-bottom: 30px;
}

.entrance-title {
  max-width: 27.9rem;
  color: #fff;
  margin-bottom: 3rem;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 3.6rem;
  font-weight: 700;
}

.entrance-description {
  color: #fff;
  max-width: 45rem;
  font-family: Karla, Roboto, sans-serif;
  font-size: 2rem;
  display: inline-block;
}

.entrance-left-col {
  display: none;
}

.form-checkboxes {
  flex-direction: column;
  align-self: flex-start;
  display: flex;
  position: relative;
}

.form__checkbox__label {
  color: #2125298c;
  margin-bottom: 10px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
}

.form__checkbox__label.form__checkbox__label--error {
  border-bottom: 1px solid red !important;
}

.form__checkbox-text {
  margin-left: .7rem;
}

.form__checkbox {
  border-radius: 15px;
  margin-bottom: 10px;
  margin-right: 7px;
}

.form-button.form-button--50w {
  width: 50%;
}

.form-button.form-button--secondary {
  color: var(--color2);
  border: 2px solid var(--color2);
  text-transform: none;
  font-weight: 400;
  background-color: #fff !important;
}

@media screen and (width >= 43rem) {
  .entrance-box {
    width: 492px;
    height: auto;
    min-height: 618px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-bottom: 54px;
    padding-left: 55px;
    padding-right: 55px;
  }

  .form-control {
    width: 372px;
  }

  .form-title {
    margin-bottom: 5rem;
  }

  .entrance-box__header {
    margin-bottom: 62px;
  }

  .form-group {
    margin-bottom: 26px;
  }

  .entrance-box--minheight {
    min-height: 634px;
  }
}

@media screen and (width >= 82rem) {
  .entrance-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .entrance-box {
    margin-top: 0;
  }

  .entrance-left-col {
    align-self: flex-start;
    padding-top: calc(100px + 5%);
    display: block;
  }

  .entrance-container {
    padding-left: 132px;
    padding-right: 132px;
  }

  .colors-btn {
    bottom: 120px;
    right: 120px;
  }
}

@media screen and (width >= 100rem) {
  .entrance-container {
    padding-left: 200px;
    padding-right: 200px;
  }

  .entrance-title {
    max-width: 50.9rem;
  }

  .entrance-description {
    max-width: 60rem;
  }

  .youdrop-char {
    margin-bottom: 60px;
  }
}

.color-picker {
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #55c8dc;
  border: none;
  border-radius: 50px;
  transition: transform .2s, width .7s, height .7s;
  animation: .6s ease-in-out 1s alternate scaleUpDown;
}

.color-picker:hover {
  transform: scale(1.15);
}

.color-picker.color-picker--bigger {
  height: 35px;
  width: 35px;
  transform: scale(1) !important;
}

.color-picker--bordered {
  height: 25px;
  width: 25px;
  border: 4px solid #fff;
}

.color-picker--bordered:hover {
  transform: scale(1.4);
}

@keyframes scaleUpDown {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.color-picker__container {
  width: 40px;
  height: auto;
  justify-content: center;
  display: flex;
}

.color-picker__container.color-picker__container--less-width {
  width: 45px;
}

@media screen and (width >= 62rem) {
  .color-picker__container {
    width: 60px;
  }
}

@media screen and (width >= 82rem) {
  .color-picker__container {
    width: 55px;
  }
}

.colors-title {
  color: #212529;
  text-align: center;
  margin-top: 3.5rem;
  margin-bottom: 9.3rem;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 3rem;
  font-weight: 700;
}

.colors-title--white {
  color: #fff;
  margin-bottom: 2rem;
}

.colors-description {
  color: #fff;
  text-align: center;
  width: 32.5rem;
  margin-bottom: 5.5rem;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
}

.container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 13%;
  padding-right: 13%;
  transition: background-color 1s linear;
  display: flex;
  position: relative;
  overflow: hidden;
}

.colors {
  grid-column-gap: 35px;
  grid-row-gap: 30px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  display: grid;
}

.color-image__container {
  min-height: 150px;
  max-height: 152px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.color-image {
  width: auto;
  max-height: 152px;
  max-width: 200px;
}

.colors-button {
  width: 22rem;
  height: 6.5rem;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 100px;
  margin-top: 50px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 800;
  transition: transform .4s;
  transform: scale(1);
}

.colors-button:hover, .colors-button:focus {
  transform: scale(1.1);
}

.colors-button.colors-button--active {
  animation: .5s ease-in infinite alternate smash;
}

@keyframes smash {
  0% {
    transform: scale(1.1);
  }

  25% {
    transform: translateX(-2px)scale(1);
  }

  50% {
    transform: translateX(-1px)scale(1);
  }

  75% {
    transform: translateX(-2px)scale(1);
  }

  100% {
    transform: translateX(0)scale(1.1);
  }
}

@media screen and (width >= 43rem) {
  .color-image__container {
    min-height: 200px;
    max-height: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .color-image {
    width: auto;
    max-height: 200px;
    max-width: 250px;
  }

  .colors-title {
    width: 35.6rem;
    margin-top: 4rem;
    font-size: 3.6rem;
  }

  .colors-button {
    margin-top: 229px;
  }

  .colors {
    grid-column-gap: 0px;
    grid-row-gap: 15px;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(8, 1fr);
    display: grid;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .colors-description {
    width: 53rem;
    margin-bottom: 6.7rem;
    font-size: 2rem;
  }
}

@media screen and (width >= 82rem) {
  .colors-button {
    position: absolute;
    bottom: 130px;
    right: 120px;
  }
}

.checkbox-container {
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
}

.checkbox--hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.checkbox {
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #2125298c;
  border-radius: 5px;
  transition: all .15s;
  display: inline-block;
}

.checkbox__mark {
  background-color: var(--color2);
  height: 100%;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  list-style-type: none;
  display: flex;
}

.checkbox__mark:before {
  content: "✓";
  color: #fff;
  display: inline-block;
  position: absolute;
}

.login-remember-password {
  color: var(--color2);
  width: 100%;
  margin-top: -5px;
  margin-bottom: 35px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.2rem;
}

.login-remember-password.login-remember-password--lessmargin {
  margin-bottom: 25px;
}

.login-remember-password a {
  color: var(--color2);
  text-align: left;
  font-size: 1.2rem;
  text-decoration: none;
}

.form-button--login {
  margin-bottom: 25px;
}

.form__label__error-message--login {
  height: 4rem;
  font-size: 1.4rem;
  font-style: Karla, Roboto, sans-serif;
  vertical-align: middle;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
  margin-bottom: 25px;
  transition: fade-in .5s;
  display: flex;
  position: static;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (width >= 62rem) {
  .form__label__error-message--login {
    margin-top: -10px;
  }

  .login-remember-password.login-remember-password--lessmargin {
    margin-bottom: 20px;
  }
}

.password-box {
  max-height: 0;
  background-color: var(--color2);
  opacity: 0;
  border-radius: 10px;
  padding: 13px 15px 5px;
  transition: max-height .5s, opacity .5s ease-in-out;
  overflow: hidden;
}

.password-box--show {
  opacity: 1;
  max-height: 500px;
  width: 100%;
  margin-bottom: 15px;
  transition: max-height .5s, opacity .5s ease-in-out;
}

.password-box__info {
  color: #fff;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
}

.password-box__badges-wrapper {
  padding-top: 10px;
}

.password-box__badge {
  vertical-align: middle;
  text-align: center;
  background-color: #fff;
  border-radius: 23px;
  margin-bottom: 15px;
  margin-right: 8px;
  padding: 6px 10px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  display: inline-block;
}

.password-box__check-icon {
  margin-right: 8px;
  display: inline;
}

.register-success-container {
  margin-top: 58px;
}

.register-success-desc {
  color: #21252980;
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  display: inline-block;
}

@media screen and (width >= 62rem) {
  .register-success-container {
    margin-top: 110px;
  }
}

.reset-container {
  margin-top: 15px;
}

.reset-container.reset-container--success {
  padding-right: 25px;
  position: absolute;
}

.reset-title {
  color: #212529;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
}

.reset-footer {
  width: 100%;
  border-top: 1px solid #70707033;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
  padding-top: 30px;
  display: flex;
}

.reset-page-desc {
  color: #21252980;
  margin-top: 30px;
  margin-bottom: 51px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 100;
  display: inline-block;
}

.reset-footer__text {
  text-align: center;
  color: #21252980;
  width: 100%;
  width: 26.2rem;
  margin-bottom: 20px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  display: block;
}

.reset-footer__link {
  color: var(--color2);
  font-family: Karla, Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.reset-icon {
  margin-bottom: 52px;
}

@media screen and (width >= 43rem) {
  .reset-container.reset-container--success {
    padding-right: 50px;
  }
}

@media screen and (width >= 62rem) {
  .reset-footer {
    margin-top: 35px;
  }

  .reset-title {
    font-size: 3rem;
  }

  .reset-icon {
    margin-bottom: 39px;
  }
}

.password-box {
  max-height: 0;
  background-color: var(--color2);
  opacity: 0;
  border-radius: 10px;
  padding: 13px 15px 5px;
  transition: max-height .5s, opacity .5s ease-in-out;
  overflow: hidden;
}

.password-box--show {
  opacity: 1;
  max-height: 500px;
  width: 100%;
  margin-bottom: 15px;
  transition: max-height .5s, opacity .5s ease-in-out;
}

.password-box__info {
  color: #fff;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
}

.password-box__badges-wrapper {
  padding-top: 10px;
}

.password-box__badge {
  vertical-align: middle;
  text-align: center;
  background-color: #fff;
  border-radius: 23px;
  margin-bottom: 15px;
  margin-right: 8px;
  padding: 6px 10px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  display: inline-block;
}

.password-box__check-icon {
  margin-right: 8px;
  display: inline;
}

.password-container {
  margin-top: 15px;
}

.password-container.password-container--success {
  padding-right: 25px;
  position: absolute;
}

.password-title {
  color: #212529;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
}

.password-page-desc {
  color: #21252980;
  margin-top: 30px;
  margin-bottom: 51px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 100;
  display: inline-block;
}

@media screen and (width >= 43rem) {
  .password-container.password-container--success {
    padding-right: 50px;
  }
}

@media screen and (width >= 62rem) {
  .password-title {
    font-size: 3rem;
  }
}

.landing__container {
  scroll-behavior: smooth;
}

.landing__top-section {
  z-index: 10;
  background-color: #55c8dc;
  padding: 64px 25px 0 20px;
}

.hide {
  display: none;
}

.landing__top-section h1 {
  color: #fff;
  margin-bottom: 40px;
  font: 900 36px / 46px Rubik;
  display: block;
}

.landing__top-section__bar {
  z-index: 100;
  background-color: #55c8dc;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px 0;
  transition: all .5s, padding-top .5s ease-in-out;
  display: flex;
  position: sticky;
  top: -2px;
}

.landing__top-section__bar-stucked {
  height: 80px;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  display: flex;
  box-shadow: 0 4px 12px #00145e1a;
}

.landing__top-space-between {
  flex-direction: column;
  margin-bottom: 86px;
  display: flex;
}

.landing__top-space-between .intro {
  margin-bottom: 80px;
}

.btn-lang-selector {
  height: 36px;
  width: 48px;
  color: #fff;
  cursor: pointer;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  background: none;
  border: 2px solid #fff;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 700;
  transition: all .3s;
  display: none;
  position: relative;
}

.btn-lang-selector.active {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
  display: flex;
}

.btn-lang-selector.stucked {
  color: #212529;
  border: 2px solid #212529;
}

.btn-lang-selector > img {
  width: 10px;
  height: 10px;
  margin-left: 2px;
  padding-top: 2px;
  display: none;
}

.btn-lang-cross-mobile {
  height: 24px;
  width: 24px;
  cursor: pointer;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  background: url("icono_cierre_menu_neg.06c3eeed.svg") no-repeat;
  border: none;
  transition: all .5s;
  display: none;
}

.btn-lang-cross-mobile.active {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  display: block;
}

.btn-lang-cross-mobile.stucked {
  background: url("icono_cierre_menu.6c167a97.svg") no-repeat;
}

.landing__top-section__bar.landing__top-section__bar-stucked .btn-lang__dropdown {
  top: 100%;
}

.landing__top-space-between picture {
  justify-content: center;
  display: flex;
}

.landing__top-space-between img {
  width: 224px;
  height: 230px;
  transform: scaleX(-1);
}

.landing__top-space-between .intro span {
  color: #fff;
  max-width: 444px;
  font: 22px / 28px Karla;
  display: block;
}

.landing__top-center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.landing__top-center h2 {
  text-align: center;
  color: #fff;
  max-width: 300px;
  margin-bottom: 39px;
  font: 900 24px / 32px Rubik;
  display: block;
}

.landing__top-center span {
  text-align: center;
  color: #fff;
  max-width: 713px;
  margin-bottom: 80px;
  font: 22px / 28px Karla;
}

.landing__top-center > img {
  width: 31px;
  height: 34px;
  margin-bottom: 14px;
  animation: 2.5s infinite pulse;
  display: none;
}

.landing-fake-picture {
  width: 375px;
  height: 247px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.landing__top-center .mockup-wrapper {
  margin-bottom: -4px;
  margin-left: -25px;
  margin-right: -25px;
  bottom: 0;
}

.landing__top-center picture {
  height: auto;
  z-index: 10;
}

.landing__top-center picture > img {
  width: 100%;
  height: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.landing__body-section {
  z-index: 20;
  background-color: #fff;
  padding: 72px 25px 0;
  position: relative;
}

.landing__body-section .intro {
  flex-direction: column;
  display: flex;
}

.landing-boxes {
  flex-direction: column;
  justify-content: space-between;
  gap: 72px;
  margin-bottom: 72px;
  display: flex;
}

.landing-box {
  width: auto;
  min-height: 282px;
  background: #fff no-repeat padding-box padding-box;
  border-radius: 10px;
  padding: 72px 24px 24px;
  position: relative;
  box-shadow: 0 24px 32px #00145e0f;
}

.landing-box span {
  color: #212529;
  text-align: left;
  margin-bottom: 16px;
  font: bold 24px / 28px Karla;
  display: block;
}

.landing-box span:last-child {
  color: #2125297c;
  margin-bottom: 0;
  font: 17px / 22px Karla;
}

.landing-box img {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -40px;
  left: 25px;
}

.landing__body-section .intro {
  justify-content: space-between;
  margin-bottom: 136px;
  display: flex;
}

.landing__body-section .intro h2 {
  color: #212529;
  max-width: 224px;
  margin-bottom: 40px;
  font: 900 36px / 43px Rubik;
}

.landing__body-section .intro span {
  letter-spacing: 0;
  color: #2125297a;
  max-width: 625px;
  font: 22px / 28px Karla;
}

.video-section {
  min-height: 403px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.video-section .video-intro {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  display: flex;
}

.video-intro span {
  color: #2125297e;
  max-width: 275px;
  font: 22px / 28px Karla;
  display: block;
}

.video-intro h2 {
  text-align: center;
  color: #212529;
  max-width: 256px;
  margin-bottom: 42px;
  font: 900 36px / 43px Rubik;
}

.landing-president-quote {
  background-color: #55c8dc;
  flex-direction: column;
  margin-top: -2px;
  margin-left: -25px;
  margin-right: -25px;
  display: flex;
}

.landing-president-quote picture {
  width: 100%;
  height: 333px;
  justify-content: center;
  display: flex;
}

.footer-picture-wrapper {
  justify-content: center;
  display: flex;
}

.landing-president-quote picture img {
  width: auto;
  height: 100%;
}

.landing-president-quote span.big-quote {
  letter-spacing: 0;
  color: #fff;
  max-width: 406px;
  margin-bottom: 56px;
  font: italic 24px / 32px Karla;
  position: relative;
}

.landing-president-quote span.big-quote:before {
  content: url("comilla_01.bee1af9f.svg");
  position: absolute;
  top: -20px;
  left: -10px;
}

.landing-president-quote span.big-quote:after {
  content: url("comilla_02.d7186f2d.svg");
  position: absolute;
  bottom: -5px;
  right: 0;
}

.landing-president-quote span {
  display: block;
}

.big-quote-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 25px 64px;
  display: flex;
}

.big-quote-wrapper > div {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.big-quote-wrapper .big-quote__president-name {
  color: #fff;
  align-self: end;
  font-size: 16px;
}

.big-quote-wrapper .big-quote__president-name strong {
  color: #fff;
  align-self: end;
  font: bold 20px / 24px Karla;
}

.landing-videoplayer {
  width: 100%;
  border-radius: 0;
}

.landing-footer {
  color: #fff;
  background-color: #212529;
  padding: 0;
  overflow: hidden;
}

.landing-footer section {
  color: #fff;
  border-top: 1px solid #fff;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  padding-top: 34px;
  padding-bottom: 34px;
  font: 16px / 21px Karla;
  display: flex;
}

.landing-footer .landing-footer__inner {
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 80px;
  padding: 72px 25px 0;
  display: flex;
}

.landing__top-section__bar div.youdrop-logo.youdrop-logo--long-blue {
  width: 151px !important;
  height: 30px !important;
}

.landing-footer .landing-footer__inner > div {
  padding-top: 50px;
}

.landing-footer .landing-footer__inner div form {
  width: 100%;
}

.landing-footer .landing-footer__inner > div h2 {
  color: #fff;
  margin-bottom: 42px;
  font: 900 36px / 43px Rubik;
}

.btn .btn-primary .btn--email span {
  display: none;
}

.landing-footer .landing-footer__inner > div > span {
  max-width: 442px;
  color: #fff;
  margin-bottom: 56px;
  font: 22px / 28px Karla;
  display: block;
}

.landing-footer__inner > picture {
  justify-content: center;
  display: flex;
}

.footer-picture > img {
  width: 240px;
  height: 203px;
}

.landing-footer__email-wrapper {
  width: 100%;
  height: 64px;
  border-radius: 100px;
  position: relative;
}

.landing-footer__email-wrapper input:focus {
  outline: none;
}

.btn.btn-primary.btn--email.btn--email--error {
  text-indent: 100px;
  width: 64px;
  height: 64px;
  min-width: 64px;
  background: url("icono_error.305df4e4.svg") no-repeat;
  margin-bottom: -3px;
  margin-right: -7px;
  bottom: 0;
}

.landing-footer__email-wrapper input {
  height: 100%;
  width: 100%;
  color: #2125297d;
  border: none;
  border-radius: 100px;
  padding-left: 32px;
  font: 18px / 22px Karla;
  position: absolute;
}

.landing-footer__email-wrapper button > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.landing-footer__email-wrapper button {
  text-transform: uppercase;
  height: 100%;
  color: #fff;
  cursor: pointer;
  background-color: #39adc1;
  border: none;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font: 900 22px / 43px Rubik;
  display: flex;
  position: absolute;
  right: 0;
}

.video-landing-container {
  width: 945px;
  height: 561px;
}

.landing-fakevideo-wrapper {
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  justify-content: center;
  align-items: center;
  margin-left: -25px;
  margin-right: -25px;
  display: flex;
  position: relative;
}

.landing-fakevideo-wrapper > div {
  width: 100%;
}

.landing-fakevideo-wrapper .landing-play-btn {
  pointer-events: none;
  position: absolute;
}

.btn.btn-follow-us {
  color: #40c0d6;
  text-transform: uppercase;
  min-width: 173px;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 100px;
  margin-top: 60px;
  padding: 20px 42px;
  font: 900 24px / 43px Rubik;
  transition: all .3s;
  display: block;
}

.btn-follow-us-link {
  width: auto;
  text-decoration: none;
  display: block;
}

.btn.btn-follow-us:hover {
  background-color: #efefef;
}

.btn.btn--email {
  transition: all .3s;
}

.btn.btn--email:hover {
  background-color: #40c0d6;
}

.btn.btn--email:disabled {
  cursor: not-allowed;
  background-color: #6f6f6f;
}

.landing-footer__email-wrapper--error {
  border: 3px solid red;
}

.landing-footer__inner div span.landing-email__error {
  color: red;
  margin-top: 16px;
  margin-bottom: 0;
  font: 18px / 22px Karla;
}

.landing-footer__inner div .email-sub-box {
  color: #2125297c;
  height: 155px;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
  animation: .5s ease-in-out crushed;
  position: absolute;
}

@keyframes crushed {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

.landing-footer__inner div .email-sub-box span {
  color: #2125297c;
  font: 22px / 28px Karla;
}

.landing-footer__inner div .email-sub-box span.email-sub-box__title {
  color: #212529;
  margin-bottom: 16px;
  font: 900 24px / 32px Rubik;
}

.landing-footer__inner div .email-sub-box img {
  animation: .5s expand;
  position: absolute;
  top: -25px;
  left: -20px;
}

@keyframes expand {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.btn-lang__dropdown {
  z-index: 50;
  width: 100%;
  background: #fff no-repeat padding-box padding-box;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 125%;
  left: 0;
  right: 0;
  box-shadow: 0 4px 12px #00145e1a;
}

.btn-lang__dropdown button {
  cursor: pointer;
  width: 100%;
  letter-spacing: 0;
  color: #212529;
  background: none;
  border: none;
  border-bottom: 1px solid #efefefe6;
  padding-top: 22px;
  padding-bottom: 25px;
  font: bold 20px / 23px Karla;
}

.btn-lang__dropdown button.selected {
  background-color: #cbeef5;
}

.btn-lang__dropdown button img {
  margin-right: 9px;
}

.btn.btn-primary.btn--email span {
  display: none;
}

.footer-separator {
  border-top: 1px solid #fff;
  justify-content: space-between;
  padding: 34px 25px;
  display: flex;
}

.footer-separator a {
  color: #fff;
  font: 16px / 21px Karla;
  text-decoration: none;
}

@media screen and (width >= 62rem) {
  .btn-lang__dropdown button.selected {
    background-color: #fff;
  }

  .btn-lang__dropdown button > img {
    display: none;
  }

  .btn-lang-cross-mobile.active {
    pointer-events: none;
    display: none;
  }

  .btn-lang-wrapper {
    position: relative;
  }

  .btn-lang-selector {
    visibility: visible;
    pointer-events: all;
    opacity: 1;
    display: flex;
  }

  .btn-lang-cross-mobile {
    display: none;
  }

  .btn-lang__dropdown {
    width: 98px;
    z-index: 50;
    flex-direction: column;
    gap: 15px;
    padding: 25px;
    display: flex;
    position: absolute;
    top: 130%;
    left: -5px;
  }

  .btn-lang__dropdown button {
    color: #212529;
    cursor: pointer;
    text-align: left;
    background: none;
    border: none;
    padding: 0;
    font: 14px / 17px Lato;
  }

  .btn-lang-selector > img {
    display: block;
  }

  .video-section {
    flex-direction: row;
    align-items: center;
    column-gap: 50px;
    margin-bottom: 160px;
  }

  .video-intro h2 {
    text-align: left;
  }

  .landing__top-section__bar div.youdrop-logo.youdrop-logo--long-blue {
    width: 111px !important;
    height: 22px !important;
  }

  .landing-box {
    max-width: 339px;
    height: auto;
  }

  .landing__top-section__bar.landing__top-section__bar-stucked {
    padding-top: 0;
  }

  .landing-footer .landing-footer__inner {
    flex-direction: row;
  }

  .landing-footer .landing-footer__inner div form {
    width: auto;
  }

  .footer-picture {
    display: flex;
  }

  .footer-picture > img {
    max-width: 515px;
    max-height: 436px;
    width: 100%;
    height: auto;
  }

  .landing-footer__email-wrapper {
    width: 440px;
  }

  .landing-footer section {
    margin-top: 80px;
  }

  .big-quote-wrapper .big-quote__president-name strong {
    font: bold 22px / 24px Karla;
  }

  .landing-president-quote span {
    display: block;
  }

  .big-quote-wrapper {
    flex: auto;
    padding: 94px 65px;
    display: flex;
  }

  .landing-president-quote span.big-quote:before {
    top: -55px;
    left: -30px;
  }

  .landing-president-quote span.big-quote:after {
    bottom: -25px;
    right: -20px;
  }

  .landing-president-quote {
    width: 100%;
    height: 478px;
    border-radius: 10px;
    flex-direction: row;
    margin: 0 0 160px;
    overflow: hidden;
  }

  .landing-president-quote picture {
    height: 100%;
    width: 100%;
    max-width: -moz-fit-content;
    max-width: fit-content;
    flex: auto;
    margin: 0;
    overflow: hidden;
  }

  .landing-footer__email-wrapper button {
    min-width: 173px;
  }

  .landing-footer__email-wrapper button > div {
    display: block;
  }

  .btn.btn-primary.btn--email div {
    display: none;
  }

  .btn.btn-primary.btn--email span {
    display: inline-block;
  }

  .landing-fakevideo-wrapper {
    margin: 0;
  }

  .landing-videoplayer {
    max-width: 713px;
    border-radius: 10px;
  }

  .video-section .video-intro {
    text-align: left;
    margin-bottom: 0;
    display: block;
  }

  .landing-boxes {
    flex-direction: row;
    gap: 72px 25px;
    margin-bottom: 159px;
  }

  .landing__body-section .intro h2 {
    margin-bottom: 0;
  }

  .landing__body-section .intro {
    display: block;
  }

  .landing__top-center > picture {
    width: auto;
    height: auto;
  }

  .landing-fake-picture {
    width: 895px;
    height: 589px;
  }

  .landing__top-center > img {
    display: block;
  }

  .landing__body-section {
    padding: 96px 139px 0;
  }

  .landing__top-section__bar {
    padding: 50px 139px 0;
  }

  .landing__top-space-between .intro {
    margin-bottom: 48px;
  }

  .landing__top-section h1 {
    margin-bottom: 50px;
    font: 900 60px / 72px Rubik;
  }

  .landing__top-space-between {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  .landing__top-space-between img {
    width: 472px;
    height: 486px;
  }

  .btn.btn-follow-us {
    width: auto;
  }

  .landing__top-section {
    padding: 70px 139px 0;
  }

  .landing-footer {
    padding: 88px 140px 0;
  }
}

@media screen and (width >= 100rem) {
  .landing__body-section, .landing__top-section__bar, .landing__top-section, .landing-footer {
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
}

@media screen and (width >= 110rem) {
  .landing__body-section, .landing__top-section__bar, .landing__top-section, .landing-footer {
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
}

.modal-wrapper {
  z-index: 1200;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: opacity .5s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-wrapper.modal-wrapper--clickable-outside {
  pointer-events: none !important;
}

.modal-wrapper.modal-wrapper--show {
  opacity: 1;
  pointer-events: all;
  animation: .5s ease-in-out fade;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal-close {
  width: 20px;
  height: 20px;
  background: var(--color1);
  border: none;
  outline: none;
  position: absolute;
  top: -30px;
  right: 25px;
  -webkit-mask: url("icono_cierre_menu_neg.06c3eeed.svg") center / 20px 20px no-repeat;
  mask: url("icono_cierre_menu_neg.06c3eeed.svg") center / 20px 20px no-repeat;
}

.modal-content {
  width: 100%;
  pointer-events: visible;
  background-color: #fff;
  flex-direction: column;
  padding: 0 25px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  display: flex;
  position: relative;
  top: 140px;
}

.modal-content.modal-content--50w {
  max-width: 50% !important;
}

.modal-content__title {
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.modal-content__title h1 {
  flex-grow: 1;
  order: -1;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 3rem;
  font-weight: 700;
}

.modal-content__body, .modal-content__body form {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.modal-content p {
  color: #21252980;
  margin-bottom: 40px;
}

.modal-content button {
  cursor: pointer;
}

.modal-content__body button {
  background-color: var(--color1);
  color: #fff;
  height: 65px;
  width: 100%;
  text-transform: uppercase;
  border: none;
  border-radius: 100px;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 0;
  padding: 20px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 900;
  display: flex;
}

.modal-buttons {
  flex-flow: column wrap;
  justify-content: space-between;
  gap: 20px 17px;
  display: flex;
}

.modal--right {
  background: none;
}

.modal--right .modal-content {
  background-color: var(--color1);
  color: #fff;
  height: 100%;
  max-width: 350px;
  border-radius: 0;
  align-self: flex-end;
  margin: 0;
  padding: 0;
}

.modal--right .modal-content form button {
  width: 100%;
  color: #fff;
  background-color: var(--color2);
  border: none;
  border-radius: 0;
  padding: 24px 0;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 900;
  position: absolute;
  bottom: 0;
  left: 0;
}

.modal--right .modal-content .modal-close {
  width: 11px;
  height: 11px;
  background-size: cover;
  top: 30px;
  right: 25px;
}

.modal--right .modal-content .modal-content__title {
  margin: 85px 30px 40px;
}

.modal--right .modal-content .modal-content__title h1 {
  font-size: 2rem;
}

.modal--right .modal-content .modal-content__body {
  margin-bottom: 70px;
  padding: 0 40px;
}

.modal--right .modal-content .form-group {
  flex-direction: column;
  margin-bottom: 30px;
  display: flex;
}

.modal--right .modal-content .form-group__title {
  color: #ffffffbf;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 9px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
}

.modal--right .modal-content .form__checkbox-text {
  color: #fff;
  margin-left: 1rem;
}

.modal--right .modal-content .checkbox-container, .modal--right .checkbox {
  height: 1.3rem;
  width: 1.3rem;
}

.modal--right .modal-content .checkbox {
  border-color: #fff;
  border-radius: 4px;
}

.modal-content--large {
  min-height: 381px;
}

@media screen and (width >= 62rem) {
  .modal-wrapper {
    z-index: 1200;
    background-color: #000c;
    justify-content: center;
  }

  .modal-wrapper.modal--right {
    background: none;
  }

  .modal-content {
    max-width: 482px;
    border-radius: 20px;
    padding: 40px 55px 58px;
    top: 0;
  }

  .modal-close {
    background: url("icono_cierre_menu_neg.06c3eeed.svg") center / 20px 20px no-repeat;
    position: absolute;
    top: -40px;
    right: 0;
    -webkit-mask: none;
    mask: none;
  }

  .modal-buttons {
    flex-direction: row;
  }
}

.footer.footer--color {
  background-color: var(--color1);
}

.footer.footer--blue {
  background-color: #39adc1;
}

.footer.footer--border {
  border-top: 1px solid #fff;
}

.footer span {
  color: #fff;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  font: 16px / 21px Karla;
  display: block;
}

@media screen and (width >= 62rem) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 139px;
    display: flex;
  }

  .footer-separator {
    border: none;
    gap: 30px;
    display: flex;
  }
}

@media screen and (width >= 100rem) {
  .footer.footer--blue {
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
}

@media screen and (width >= 110rem) {
  .footer.footer--blue {
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
}

.legal-content {
  background-color: #f4f6f7;
  flex-direction: column;
  align-items: center;
  padding: 56px 25px;
  display: flex;
}

.legal-content h1 {
  color: #212529;
  text-align: center;
  margin-bottom: 80px;
  font: bold 36px / 47px Rubik;
}

.legal-content h2 {
  color: #212529;
  margin-bottom: 25px;
  font: bold 24px / 31px Rubik;
}

.legal-section {
  width: 100%;
}

.legal-content .legal-section {
  margin-bottom: 80px;
}

.legal-content .legal-section ul:last-child, .legal-content .legal-section span:last-child {
  margin-bottom: 0;
}

.legal-content span {
  color: #212529;
  margin-bottom: 25px;
  font: 18px / 23px Karla;
  display: block;
}

.legal-content ul {
  color: #212529;
  margin-bottom: 25px;
  padding-left: 15px;
  font: 18px / 23px Karla;
}

.legal-icon {
  width: 91px;
  height: 100px;
  margin-bottom: 35px;
}

.legal-footer {
  background-color: #0000;
  border-top: 1px solid #fff;
}

.legal-footer.legal-footer--color {
  background-color: var(--color1);
  border: none;
}

.legal-footer.legal-footer--blue {
  background-color: #39adc1;
  border: none;
}

.legal-footer span {
  color: #fff;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  font: 16px / 21px Karla;
  display: block;
}

@media screen and (width >= 62rem) {
  .legal-content {
    padding: 50px 139px 0;
  }
}

@media screen and (width >= 100rem) {
  .legal-content {
    padding-top: 50px;
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
}

@media screen and (width >= 110rem) {
  .legal-content {
    padding-top: 50px;
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
}

.navbar__container {
  flex-direction: column;
  display: flex;
}

.navbar {
  z-index: 100;
  min-height: 75px;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  transition: background-color .3s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: 0 4px 12px #00145e1a;
}

.navbar__wrapper {
  z-index: 1000;
  position: sticky;
  top: 0;
}

.btn-lang-selector.btn-lang-selector--trans {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin-left: 15px;
}

.navbar__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar__category {
  border: 1px solid var(--color2);
  color: var(--color2);
  cursor: pointer;
  background-color: #0000;
  border-radius: 100px;
  padding: 7px 18px;
  font: bold 1.4rem / 1.7rem Karla;
}

.navbar__category--selected {
  background-color: var(--color2);
  color: #fff;
}

.navbar__categories {
  width: 100%;
  flex-direction: row;
  gap: 1.5rem;
  margin-bottom: 30px;
  display: flex;
}

.navbar__categories__filter-btn {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin-left: auto;
}

.navbar.navbar--menu-on {
  background-color: var(--color2);
  width: 100%;
  box-shadow: none;
  position: sticky;
  top: 0;
}

.navbar__right-icons {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.navbar__search-box {
  align-items: center;
  display: flex;
  position: static;
  width: calc(100% - 50px) !important;
}

.navbar__logo {
  width: 150px;
  height: 30px;
}

.navbar__search-input {
  color: #21252940;
  border: none;
  border-radius: 10px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 2rem;
}

.navbar__search-input:focus {
  outline: none;
}

.navbar__magnifier {
  pointer-events: none;
  margin-right: 35px;
}

.navbar__magnifier.navbar__magnifier--nomargin {
  margin-right: 15px;
}

.navbar__ham {
  pointer-events: none;
}

.navbar__ham--right {
  margin-left: auto;
}

.navbar__search-button {
  cursor: pointer;
  background: none;
  border: none;
}

.navbar__dropdown-desktop {
  width: auto;
  background: #fff no-repeat padding-box padding-box;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  position: absolute;
  bottom: -185px;
  right: 0;
  box-shadow: 0 4px 12px #00145e1a;
}

.navbar__dropdown-destkop__separator {
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}

.navbar__dropdown-desktop button {
  color: #212529;
  width: max-content;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: none;
  margin-left: 25px;
  margin-right: 25px;
  font: 1.4rem / 1.7rem Lato;
  display: inline-block;
}

.navbar__dropdown {
  width: 100%;
  height: 0;
  min-height: 0;
  transition: min-height .4s ease-in-out;
  position: fixed;
  overflow: auto;
}

.navbar__dropdown.navbar__dropdown--active {
  min-height: 100vh;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 185px;
  top: 0;
}

.navbar__dropdown__footer {
  height: 165px;
  width: 100%;
  background-color: var(--color2);
  pointer-events: none;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  display: flex;
  position: fixed;
  bottom: 0;
}

.navbar__dropdown_footer__btn {
  color: var(--color2);
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 100px;
  margin-bottom: 21px;
  padding: 15px 30px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
}

.navbar__dropdown_footer__btn--filters {
  background-color: var(--color3);
  color: #fff;
  border: none;
  border-radius: 100px;
  padding: 20px 70px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
}

.navbar__dropdown_footer__btn--logout {
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
}

.navbar__dropdown-item {
  color: #212529;
  height: 68px;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #70707033;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  display: flex;
}

.navbar__dropdown-items-wrapper {
  opacity: 1;
  position: relative;
}

.navbar__dropdown-arrow {
  margin-left: auto;
  margin-right: 5px;
}

.navbar__myaccount-btn {
  background-color: var(--color2);
  height: 30px;
  width: 30px;
  cursor: pointer;
  border: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.navbar__myaccount-btn img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.navbar__myaccount-btn img.svg-loaded {
  pointer-events: none;
  height: 16px;
  width: 16px;
}

.navbar__notifications-btn {
  cursor: pointer;
  background: none;
  border: none;
  margin-right: 25px;
  padding: 0;
}

.navbar__notifications-btn object {
  pointer-events: none;
  margin-top: 2px;
}

.navbar__option {
  color: #21252940;
  border-bottom: 2px solid #0000;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  transition: all .5s;
}

.navbar__option:hover {
  border-bottom: 2px solid var(--color2);
}

.navbar__option:hover a {
  color: var(--color2);
}

.navbar__option a {
  color: #21252940;
  text-decoration: none;
  transition: all .5s;
}

.navbar__option.navbar__option--selected {
  border-bottom: 2px solid var(--color2);
}

.navbar__option.navbar__option--selected a {
  color: var(--color2);
}

.navbar__options-wrapper {
  flex: 1;
  align-items: center;
  column-gap: 32px;
  margin-left: 30px;
  display: flex;
}

.navbar__option:last-child {
  background-color: var(--color2);
  border: none;
  border-radius: 100px;
  padding: 10px 14px;
}

.navbar__option:last-child a {
  color: #fff;
}

@media screen and (width >= 62rem) {
  .navbar__wrapper {
    position: relative;
  }

  .navbar__magnifier {
    margin-right: 25px;
  }

  .navbar {
    padding: 0 80px;
  }

  .navbar.navbar--menu-on {
    background-color: #fff;
    position: relative;
  }

  .navbar__wrapper-mb {
    padding-top: 75px;
  }

  .navbar__inner--pt {
    min-height: 75px;
  }
}

@media screen and (width >= 82rem) {
  .navbar {
    padding: 0 132px;
  }
}

@media screen and (width >= 100rem) {
  .navbar {
    padding: 0 275px;
  }
}

._3MUvva_title {
  color: var(--color1);
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 11px;
  font: bold 1.4rem / 2.1rem Karla;
}

._3MUvva_box {
  height: 152px;
  background: #fff no-repeat padding-box padding-box;
  flex-direction: row;
  margin-left: -25px;
  margin-right: -25px;
  padding: 20px 0;
  display: flex;
  overflow-y: auto;
}

._3MUvva_innerBox {
  border-right: 1px solid rgba(var(--rgbcolor), .3);
  width: auto;
  padding-left: 25px;
  padding-right: 25px;
}

._3MUvva_innerBox:last-child {
  border-right: none;
}

._3MUvva_statNumber {
  color: var(--color1);
  font: bold 3rem / 3.9rem Rubik;
  display: block;
}

._3MUvva_statName {
  text-align: left;
  color: #212529;
  font: bold 1.6rem / 2.1rem Karla;
  display: block;
}

@media screen and (width >= 62rem) {
  ._3MUvva_box {
    min-width: 425px;
    max-height: 120px;
    border-radius: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  ._3MUvva_statNumber {
    margin-bottom: 13px;
    font: bold 1.8rem / 2.1rem Rubik;
  }

  ._3MUvva_innerBox {
    padding-left: 15px;
    padding-right: 15px;
  }

  ._3MUvva_statName {
    font: bold 1.2rem / 1.6rem Karla;
  }

  ._3MUvva_title {
    font: bold 1.4rem / 2.1rem Karla;
  }
}

@media screen and (width >= 82rem) {
  ._3MUvva_box {
    max-height: 135px;
    padding-top: 26px;
    padding-bottom: 26px;
  }

  ._3MUvva_statNumber {
    font: bold 24px / 21px Rubik;
  }

  ._3MUvva_innerBox {
    min-width: 136px;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  ._3MUvva_statName {
    font: bold 13px / 17px Karla;
  }

  ._3MUvva_innerBox {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.TIushq_boxProgressWrapper {
  width: 100%;
  height: 7px;
  margin-bottom: 15px;
  display: flex;
  position: relative;
}

.TIushq_boxProgress {
  accent-color: var(--color1);
  width: 100%;
  height: 7px;
  border: 0 solid #0000;
  border-radius: 10px;
  display: block;
}

.TIushq_boxProgressCircle {
  width: 15px;
  height: 15px;
  background-color: var(--color1);
  z-index: 10;
  border-radius: 50%;
  transition: all .3s ease-in-out;
  top: -4px;
  left: 0;
  position: absolute !important;
}

.TIushq_boxProgressCircleText {
  color: #fff;
  background-color: var(--color1);
  border-radius: 10px;
  padding: 3px 7px;
  font: bold 1.4rem / 2.1rem Rubik;
  display: block;
  top: -45px;
  position: absolute !important;
}

.TIushq_boxProgress::-webkit-progress-value {
  background-color: var(--color1);
  border-radius: 10px;
}

.TIushq_boxProgress::-moz-progress-bar {
  background-color: var(--color1);
  border-radius: 10px;
}

.TIushq_boxProgressCircleText:after {
  content: "";
  border: 10px solid var(--color1);
  border-color: var(--color1) transparent transparent transparent;
  margin-left: -5px;
  display: block;
  bottom: 0;
  left: 40%;
  transform: translateY(100%);
  position: absolute !important;
}

.x4fp_q_boxPoints {
  width: 100%;
  height: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13px;
  display: flex;
  position: absolute;
  bottom: 25px;
}

.x4fp_q_boxPoint {
  width: 10px;
  height: 10px;
  z-index: 40;
  cursor: pointer;
  background: #ffffff75 no-repeat padding-box padding-box;
  border: none;
  border-radius: 50%;
  bottom: 100px;
  left: 50%;
}

.x4fp_q_boxPoint.x4fp_q_active {
  background: #fff no-repeat padding-box padding-box;
}

._6vcUAq_box {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  min-height: 417px;
  max-width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #000 no-repeat padding-box padding-box;
  border-radius: 10px;
  display: flex;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
}

._6vcUAq_box::-webkit-scrollbar {
  display: none;
}

._6vcUAq_boxInner {
  scroll-snap-align: center;
  width: 100%;
  transform-origin: center;
  -webkit-user-select: none;
  user-select: none;
  background: url("portada_Foto2.d68487ab.jpeg") center / cover no-repeat padding-box padding-box;
  flex-shrink: 0;
  padding: 30px 20px 25px;
  transition: transform .5s, background-size .3s ease-in-out;
  position: relative;
}

._6vcUAq_box:hover ._6vcUAq_boxInner {
  background-size: 110%;
}

._6vcUAq_box:hover ._6vcUAq_boxInner ._6vcUAq_boxTitle {
  text-decoration: underline;
}

._6vcUAq_box:hover ._6vcUAq_boxInner ._6vcUAq_boxButton {
  background: var(--color1) 0% 0% no-repeat padding-box;
  color: #fff;
}

._6vcUAq_boxInner * {
  z-index: 10;
  position: relative;
}

._6vcUAq_boxTitle {
  text-align: left;
  letter-spacing: -.78px;
  color: #fff;
  margin-bottom: 20px;
  font: bold 2.6rem / 3.4rem Rubik;
  display: block;
}

._6vcUAq_boxDesc {
  text-align: left;
  letter-spacing: 0;
  color: #fff;
  min-height: 84px;
  margin-bottom: 35px;
  font: 16px / 21px Karla;
  display: block;
}

._6vcUAq_boxButton {
  color: #212529;
  height: 40px;
  cursor: pointer;
  background: #fff no-repeat padding-box padding-box;
  border: none;
  border-radius: 100px;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  padding: 0 20px;
  transition: all .3s;
  display: flex;
}

._6vcUAq_boxButton:hover {
  background: var(--color1) 0% 0% no-repeat padding-box;
  color: #fff;
}

._6vcUAq_boxHead {
  cursor: pointer;
  background: none;
  border: none;
  flex-direction: column;
  margin-bottom: 107px;
  display: flex;
}

._6vcUAq_boxGoalLabel {
  text-align: left;
  letter-spacing: 0;
  color: #fff;
  margin-bottom: 10px;
  font: bold 1.6rem / 2.1rem Rubik;
  display: block;
}

._6vcUAq_boxGoalWrapper {
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 33px;
  display: flex;
}

._6vcUAq_blackBackground {
  width: 100%;
  height: 100%;
  filter: brightness(.35);
  object-fit: cover;
  background-color: #000a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0 !important;
}

._6vcUAq_boxWrapper {
  width: 100%;
  position: relative;
}

@media screen and (width >= 62rem) {
  ._6vcUAq_boxGoalLabel {
    font: bold 1.2rem / 1.8rem Rubik;
  }

  ._6vcUAq_boxHead {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  ._6vcUAq_boxDesc {
    margin-bottom: 25px;
  }

  ._6vcUAq_boxWrapper {
    height: auto;
  }

  ._6vcUAq_box {
    min-height: 312px;
    max-height: 100%;
    max-width: 850px;
  }

  ._6vcUAq_boxButton {
    min-width: 149px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    font: bold 1.4rem / 1.5rem Karla;
    position: absolute;
    right: 0;
  }

  ._6vcUAq_boxTitle {
    margin-bottom: 26px;
  }
}

._6vcUAq_boxGoalNoMargin {
  margin-bottom: 0;
}

@media screen and (width >= 82rem) {
  ._6vcUAq_boxInner {
    background-size: 100%;
  }
}

.AGAK-G_cardBox {
  width: 278px;
  min-width: 278px;
  background-color: #fff;
  border-radius: 10px;
  flex: 1;
  transition: all .3s ease-in-out;
  position: relative;
}

.AGAK-G_cardBoxDisabled {
  opacity: .7;
  pointer-events: none;
}

.AGAK-G_cardTitle {
  color: #212529;
  text-align: left;
  margin-bottom: 12px;
  font: bold 1.6rem / 2.1rem Rubik;
  display: block;
}

.AGAK-G_cardDesc {
  color: #21252983;
  text-align: left;
  text-overflow: ellipsis;
  height: 60px;
  font: 1.6rem / 2.1rem Karla;
  display: block;
  overflow: hidden;
}

.AGAK-G_cardHeadWrapper {
  min-height: 117px;
  margin-bottom: 34px;
}

.AGAK-G_innerCard {
  width: 100%;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 23px 20px;
  display: block;
}

.AGAK-G_cardHeaderImage {
  width: 100%;
  height: 140px;
  background: url("portada_Foto2.d68487ab.jpeg") center / cover no-repeat padding-box padding-box;
  border-radius: 10px 10px 0 0;
  position: relative;
}

.AGAK-G_boxGoalLabel {
  text-align: left;
  color: #212529;
  font: bold 1.6rem / 2.1rem Rubik;
  display: block;
}

.AGAK-G_boxGoalWrapper {
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.AGAK-G_daysLeftLabel {
  text-align: left;
  letter-spacing: 0;
  color: var(--color1);
  font: italic bold 1.4rem / 1.7rem Karla;
  display: block;
}

.AGAK-G_cardBox .AGAK-G_cardHeaderImage .AGAK-G_saveButton {
  background: none;
  border: none;
  display: none;
}

.AGAK-G_cardBox:hover .AGAK-G_cardHeaderImage .AGAK-G_saveButton {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
}

.AGAK-G_cardBox:hover .AGAK-G_cardHeaderImage:before {
  content: attr(data-before);
  color: #fff;
  background: var(--color1) 0% 0% no-repeat padding-box;
  text-transform: capitalize;
  border-radius: 6px;
  padding: 6px 10px;
  font: italic bold 1.2rem / 12px Karla;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.AGAK-G_cardBox:hover .AGAK-G_innerCard .AGAK-G_cardTitle {
  color: var(--color1);
  text-decoration: underline;
}

.AGAK-G_cardBox:hover .AGAK-G_innerCard .AGAK-G_cardDesc {
  color: var(--color1);
}

@media screen and (width >= 62rem) {
  .AGAK-G_cardBox {
    min-width: 264px;
    max-width: 264px;
    height: 430px;
  }
}

@media screen and (width >= 102rem) {
  .AGAK-G_cardBox {
    min-width: 290px;
  }
}

.nlilgG_wrapper {
  width: 100%;
  flex-direction: row;
  gap: 16px;
  padding-right: 25px;
  display: flex;
  overflow-x: auto;
}

@media screen and (width >= 62rem) {
  .nlilgG_wrapper {
    width: 100%;
    height: 440px;
    flex-flow: wrap;
    justify-content: space-between;
    gap: 15px;
    overflow: hidden;
  }
}

.S3GKWW_header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  display: flex;
}

.S3GKWW_header > button {
  color: var(--color1);
  border: 2px solid var(--color1);
  cursor: pointer;
  background: none;
  border-radius: 100px;
  padding: 9px 16px;
  transition: all .3s;
}

.S3GKWW_header .S3GKWW_showButton:hover {
  background: var(--color1);
  color: #fff;
}

.S3GKWW_arrows {
  flex-direction: row;
  align-items: center;
  display: none;
}

.S3GKWW_arrows button {
  cursor: pointer;
  width: 7.5px;
  height: 15px;
  background: none;
  border: none;
  padding: 14px;
}

.S3GKWW_arrows button:first-child {
  background-color: var(--color1);
  -webkit-mask: url("left_arrow_slider.665e5b09.svg") center no-repeat;
  mask: url("left_arrow_slider.665e5b09.svg") center no-repeat;
}

.S3GKWW_arrows button:last-child {
  background-color: var(--color1);
  -webkit-mask: url("right_arrow_slider.6178eb32.svg") center no-repeat;
  mask: url("right_arrow_slider.6178eb32.svg") center no-repeat;
}

.S3GKWW_header > button:hover {
  background: var(--color1);
  color: #fff;
}

.S3GKWW_header h2 {
  color: #212529;
  font: bold 2rem / 2.6rem Rubik;
  display: block;
}

.home-cards-wrapper {
  margin-right: -25px;
}

.home-footer-slidercards {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.left-col-home {
  margin-bottom: 35px;
}

@media screen and (width >= 62rem) {
  .home-footer-slidercards {
    flex-direction: row;
  }

  .home-cards-title__arrows {
    display: flex;
  }

  .home-cards-title > button {
    margin-left: 30px;
    margin-right: auto;
  }

  .left-col-home {
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
    display: flex;
  }
}

._9BpAIq_projectsContainer {
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

._9BpAIq_categoryTitle {
  margin-bottom: 2rem;
}

._9BpAIq_noProjectsWrapper {
  text-align: center;
  color: #5a5a5a;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-top: 17rem;
  display: flex;
}

@media screen and (width >= 62rem) {
  ._9BpAIq_projectsContainer {
    justify-content: flex-start;
  }
}

.panel-container {
  background-color: #f4f6f7;
  flex-direction: column;
  flex: auto;
  padding: 0 25px;
  display: flex;
}

.panel-menu {
  min-width: 264px;
  flex-direction: column;
  margin-top: 40px;
  display: flex;
}

.panel-menu__title {
  display: none;
}

.panel-menu__options {
  height: 55px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  transition: height .3s ease-in-out;
  display: flex;
  overflow: hidden;
}

.panel-menu__options button {
  width: 100%;
  color: #212529;
  cursor: pointer;
  height: 55px;
  opacity: 0;
  background: none;
  border: none;
  flex-direction: row;
  flex: none;
  align-items: center;
  gap: 21px;
  padding: 0 20px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  transition: opacity .3s ease-in-out;
  display: flex;
}

.panel-menu__options.panel-menu__on {
  height: 220px;
}

.panel-menu__options.panel-menu__on button {
  opacity: 1;
}

.panel-menu__options button.active {
  opacity: 1;
  background: url("perfil_desplegable_down_smartphone.596a2632.svg") 100% no-repeat content-box content-box;
  order: -1;
}

.panel-menu__options.panel-menu__on button.active {
  background-image: url("perfil_desplegable_up_smartphone.d42cabf8.svg");
}

.panel-menu__options button:hover.active {
  background-color: initial;
}

.panel-menu__options button:hover {
  background-color: var(--color3);
}

.panel-menu__options button > div {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.panel-menu__options button > div.ico-account {
  background-image: url("compte_perfil.63cf8bd7.svg");
}

.panel-menu__options button > div.ico-history {
  background-image: url("compte_historial.e3cfc366.svg");
}

.panel-menu__options button > div.ico-saved {
  background-image: url("compte_projectes_guardats.8996b2f6.svg");
}

.panel-menu__options button > div.ico-help {
  background-image: url("compte_ajuda.e93b7024.svg");
}

.panel-selected-page {
  flex-direction: column;
  flex: 1;
  margin-top: 40px;
  display: flex;
}

.page-container {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.page-container__title {
  color: #212529;
  margin-bottom: 30px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
}

.page-container__subtitle {
  color: #212529;
  margin-bottom: 30px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
}

.page-card {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 0 20px;
  display: flex;
}

.fade-hide {
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s ease-in-out;
}

.fade-show {
  opacity: 1;
  pointer-events: all;
  animation: .5s ease-in-out fade;
}

@media screen and (width >= 62rem) {
  .panel-container {
    flex-direction: row;
    gap: 93px;
    padding: 0 132px;
  }

  .panel-menu__title {
    color: #cacaca;
    margin-bottom: 21px;
    font-family: Karla, Roboto, sans-serif;
    font-size: 1.4rem;
    display: block;
  }

  .panel-menu {
    margin-top: 73px;
  }

  .panel-menu__options {
    height: auto;
  }

  .panel-menu__options button {
    height: 55px;
    opacity: 1;
    padding: 0 16px;
  }

  .panel-menu__options button.active, .panel-menu__options button:hover.active {
    color: #fff;
    order: 0;
    background: var(--color2) !important;
  }

  .panel-menu__options button.active > div {
    background: #fff;
  }

  .panel-menu__options button.active > div.ico-account {
    -webkit-mask: url("compte_perfil.63cf8bd7.svg") center no-repeat;
    mask: url("compte_perfil.63cf8bd7.svg") center no-repeat;
  }

  .panel-menu__options button.active > div.ico-history {
    -webkit-mask: url("compte_historial.e3cfc366.svg") center no-repeat;
    mask: url("compte_historial.e3cfc366.svg") center no-repeat;
  }

  .panel-menu__options button.active > div.ico-saved {
    -webkit-mask: url("compte_projectes_guardats.8996b2f6.svg") center no-repeat;
    mask: url("compte_projectes_guardats.8996b2f6.svg") center no-repeat;
  }

  .panel-menu__options button.active > div.ico-help {
    -webkit-mask: url("compte_ajuda.e93b7024.svg") center no-repeat;
    mask: url("compte_ajuda.e93b7024.svg") center no-repeat;
  }

  .panel-selected-page {
    margin-top: 60px;
    overflow: auto;
  }
}

@media screen and (width >= 100rem) {
  .panel-container {
    padding-left: 275px;
    padding-right: 275px;
  }
}

.form-control[type="text"], .form-control[type="password"], .form-control[type="email"] {
  color: #9d9d9d !important;
  border: 1px solid #e5e5e5 !important;
}

.form-control[type="text"]:-ms-input-placeholder, .form-control[type="password"]:-ms-input-placeholder, .form-control[type="email"]:-ms-input-placeholder {
  color: #212529;
}

.form-control[type="text"]:focus, .form-control[type="password"]:focus, .form-control[type="email"]:focus {
  outline: none;
  border: 2px solid var(--color2) !important;
}

.font-color-error {
  color: red;
  margin-top: 20px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  display: block;
}

.is-invalid {
  border: 1px solid red !important;
}

.account-data__row {
  color: #212529;
  border-bottom: 1px solid #e5e5e5;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  padding: 23px 0;
  font-family: nunito-sans, sans-serif;
  font-size: 1.6rem;
  display: flex;
}

.account-data__row:last-child {
  border-bottom: none;
}

.account-data__row > div {
  flex: 1;
  display: flex;
}

.account-data__row > div:last-child {
  justify-content: end;
}

.account-data__row-header {
  width: 100%;
  color: #21252980;
  flex: 0 auto !important;
}

.account-data__avatar {
  background: var(--color2);
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
  overflow: hidden;
}

.account-data__avatar img {
  height: 100%;
  width: 100%;
}

.account-data__avatar img.svg-loaded {
  height: 30px;
  width: 30px;
}

.avatar-image {
  width: 110px;
  height: 110px;
  background-color: #000;
  border: none;
  border-radius: 100%;
  align-self: center;
  margin-top: 20px;
}

.avatar-image img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.account-data__row button {
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-color: var(--color1);
  border: none;
  font-family: Karla, Roboto, sans-serif;
  font-size: 0;
  font-weight: 700;
  -webkit-mask: url("perfil_editar_campo_smartphone.01ca2521.svg") center no-repeat;
  mask: url("perfil_editar_campo_smartphone.01ca2521.svg") center no-repeat;
}

.color-container {
  align-items: center;
  row-gap: 30px;
  padding: 28px 20px;
}

.colors {
  grid-gap: 25px;
}

.color-picker__container {
  width: auto;
}

.color-picker--bigger {
  outline-offset: -2px;
  outline: 4px solid #000000e6 !important;
}

.color-name {
  color: #21252940;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
}

.account-delete {
  color: #21252980;
  justify-content: space-between;
  gap: 15px;
  padding: 32px 20px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}

.account-delete button {
  color: var(--color1);
  white-space: nowrap;
  cursor: pointer;
  background: none;
  border: none;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  display: flex;
}

@media screen and (width >= 62rem) {
  .account-data__row-header {
    width: auto;
    flex: 1 !important;
  }

  .account-data__avatar {
    width: 35px;
    height: 35px;
    margin-top: 0;
  }

  .account-data__avatar img.svg-loaded {
    height: 17px;
    width: 17px;
  }

  .account-data__row button {
    height: auto;
    width: auto;
    color: var(--color1);
    -webkit-mask: initial;
    mask: initial;
    -webkit-mask: initial;
    background-color: #0000;
    font-size: 1.8rem;
  }

  .color-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .color-container .color-picker {
    height: 30px;
    width: 30px;
  }

  .colors {
    grid-gap: 15px;
    grid-template-rows: repeat(2, 1fr) !important;
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .account-delete {
    flex-direction: row;
    align-items: center;
    column-gap: 25px;
  }
}

@media screen and (width >= 82rem) {
  .colors {
    grid-template-rows: repeat(1, 1fr) !important;
    grid-template-columns: repeat(8, 1fr) !important;
  }
}

.history-columns {
  color: #212529;
  flex-direction: row;
  gap: 40px;
  margin: 0 20px 25px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  display: none;
}

.history-columns > div {
  flex: none;
  display: flex;
}

.history-row {
  color: #21252980;
  border-bottom: 1px solid #70707033;
  flex-direction: column;
  padding: 25px 0;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
}

.history-row:last-child {
  border: none;
}

.history-row button {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
}

.history-row span {
  color: #212529;
  font-size: 1.6rem;
  font-weight: 700;
}

.history-container {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.history-container > div {
  flex-flow: wrap;
  transition: all .3s ease-in-out;
  display: flex;
}

.history-container > div > div {
  display: flex;
}

.history-container > div:first-child > div {
  flex: 1 0 auto;
  overflow: hidden;
}

.history-row > .history-container > div:last-child {
  height: 0;
  opacity: 0;
}

.history-row.active > .history-container > div:last-child {
  height: 61px;
  opacity: 1;
}

.history-container > div:last-child > div {
  flex-direction: column;
  flex: 1 0 auto;
  gap: 10px;
}

.history-name {
  width: 100%;
  order: 1;
  justify-content: space-between;
  flex-direction: row !important;
  column-gap: 50px !important;
}

.history-row button.history-arrow {
  height: 16px;
  width: 16px;
  background: url("perfil_desplegable_down_smartphone.596a2632.svg") center no-repeat;
  flex-shrink: 0;
}

.history-row.active button.history-arrow {
  background-image: url("perfil_desplegable_up_smartphone.d42cabf8.svg");
}

.history-date {
  width: 100%;
  height: 0;
  opacity: 0;
  order: 2;
  transition: all .3s ease-in-out;
}

.history-row.active .history-date {
  height: 22px;
  opacity: 1;
  margin: 10px 0 30px;
}

.history-receipt button {
  color: var(--color1);
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
}

.history-donation {
  flex: auto !important;
}

.history-receipt {
  flex: 1 !important;
}

@media screen and (width >= 82rem) {
  .history-columns {
    display: flex;
  }

  .history-columns > div:first-child {
    width: 140px;
  }

  .history-columns > div:nth-of-type(2) {
    flex: 1;
  }

  .history-columns > div:nth-of-type(3) {
    width: 65px;
  }

  .history-columns > div:last-child {
    width: 150px;
  }

  .history-row span {
    display: none;
  }

  .history-container {
    flex-direction: row;
    gap: 40px !important;
  }

  .history-container > div {
    flex-flow: row;
    flex: none;
    align-items: center;
    transition: none;
    gap: 40px !important;
  }

  .history-container > div > div {
    flex: none !important;
  }

  .history-row > .history-container > div:first-child {
    flex: 1;
    margin: 0 !important;
  }

  .history-row > .history-container > div:last-child {
    height: auto;
    opacity: 1;
  }

  .history-container > div > .history-name {
    order: 2;
    flex: 1 !important;
  }

  .history-row button.history-arrow {
    display: none;
  }

  .history-row.active .history-date {
    margin: 0;
  }

  .history-date {
    width: 140px;
    height: auto;
    opacity: 1;
    order: 1;
    transition: none;
  }

  .history-donation {
    width: 65px;
  }

  .history-receipt {
    width: 150px;
  }
}

.saved-projects-wrapper {
  flex-flow: wrap;
  gap: 1.5rem;
  display: flex;
}

.savedcrowfundings-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.9rem;
  padding-top: 40px;
  display: flex;
}

.savedcrowfundings-wrapper img {
  max-width: 90px;
}

.savedcrowfundings-wrapper__text {
  color: #6e6e6e;
  font-size: 2rem;
  font-weight: 700;
}

div.panel-container.help {
  background-image: url("ilustracion_ayuda.1949584b.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 426px 242px;
}

.help-text {
  color: #212529;
  margin-bottom: 30px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
}

.help-mail {
  color: var(--color1);
  width: -moz-fit-content;
  width: fit-content;
  font-family: Karla, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (width >= 62rem) {
  div.panel-container.help {
    background-position: calc(100% + 200px) 100%;
    background-size: 809px 460px;
  }

  div.panel-container.help div.page-container {
    min-height: 600px;
  }
}

@media screen and (width >= 82rem) {
  div.panel-container.help {
    background-position: 100% 100%;
    background-size: 450px 256px;
  }
}

video {
  width: 95%;
  margin-bottom: 8px;
  margin-left: 2.5%;
}

.bMKyPG_projectContainer {
  background-color: #f4f6f7;
  padding-top: 250px;
  padding-bottom: 0;
  position: relative;
}

.bMKyPG_headerBackground {
  background-color: var(--color3);
  height: 225px;
  width: 100%;
  background-position: 50%;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.bMKyPG_saveButton {
  cursor: pointer;
  background: none;
  border: none;
  margin: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bMKyPG_button {
  cursor: pointer;
  width: 100%;
  background: var(--color2) 0% 0% no-repeat padding-box;
  color: #fff;
  border: none;
  border-radius: 100px;
  padding: 20px;
  font: 700 1.8rem / 2.2rem Rubik;
  transition: all .7s;
}

.bMKyPG_button:hover {
  background: var(--color1);
}

.bMKyPG_button:disabled {
  color: #9e9e9e;
  cursor: not-allowed;
  background: #e0e0e0;
}

.bMKyPG_donationText {
  margin-top: 25px;
}

.bMKyPG_tags {
  color: var(--color1);
  margin-bottom: 3rem;
  font: 1.6rem / 2.1rem Lato;
  display: block;
}

.bMKyPG_title {
  margin-bottom: 1.5rem;
}

.bMKyPG_subTitle {
  margin-bottom: 2.2rem;
}

.bMKyPG_text {
  color: #212529;
  font: 1.6rem / 2.1rem Karla;
  display: block;
}

.bMKyPG_boxGoalLabel {
  color: #212529;
  font: bold 2.4rem / 1.8rem Rubik;
  display: block;
}

.bMKyPG_boxGoalWrapper {
  text-transform: uppercase;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  display: flex;
}

.bMKyPG_boxGoalLabelLeft {
  color: #2125297e;
  font: bold 1.2rem / 1.8rem Rubik;
  display: block;
}

.bMKyPG_daysLeftLabel {
  text-align: left;
  width: 100%;
  color: var(--color2);
  margin-bottom: 30px;
  font: italic bold 1.8rem / 2.2rem Karla;
  display: block;
}

.bMKyPG_odsWrapper {
  flex-flow: wrap;
  gap: 7px;
  display: flex;
}

.bMKyPG_progressBarWrapper {
  width: 100%;
  margin-top: 45px;
}

.bMKyPG_recommendedProjects {
  background-color: var(--color3);
  padding: 25px 25px 0;
}

.bMKyPG_headerDesktop, .bMKyPG_col1 {
  margin-bottom: 4rem;
}

@media screen and (width >= 62rem) {
  .bMKyPG_recommendedProjects {
    padding-left: 80px;
    padding-right: 80px;
  }

  .bMKyPG_text {
    color: #212529;
    font: 1.8rem / 2.3rem Karla;
    display: block;
  }

  .bMKyPG_title {
    font-size: 4.2rem;
  }

  .bMKyPG_subTitle {
    font-size: 3.6rem;
  }

  .bMKyPG_projectContainer {
    padding-top: 35px;
  }

  .bMKyPG_gridContainer {
    grid-template-columns: repeat(5, 1fr);
    display: grid;
  }

  .bMKyPG_col1 {
    grid-column: 1 / 4;
    margin-bottom: 0;
  }

  .bMKyPG_col2 {
    grid-column: 4 / 6;
    padding-left: 30px;
  }

  .bMKyPG_headerImageDesktop {
    height: 284px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .bMKyPG_headerDesktop {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .bMKyPG_saveButtonDesktop {
    border: 2px solid var(--color2);
    height: 40px;
    color: var(--color2);
    cursor: pointer;
    background: none;
    border-radius: 100px;
    align-self: flex-end;
    margin-bottom: 8px;
    padding: 11px 18px;
    font: bold 1.4rem / 1.7rem Karla;
    transition: all .3s;
  }

  .bMKyPG_saveButtonDesktop.bMKyPG_saving {
    animation: 1.2s linear infinite bMKyPG_loading;
  }

  @keyframes bMKyPG_loading {
    0% {
      background: var(--color2);
      color: #fff;
    }

    50% {
      color: var(--color2);
      background: none;
    }

    100% {
      background: var(--color2);
      color: #fff;
    }
  }

  .bMKyPG_saveButtonDesktop:hover {
    background: var(--color2);
    color: #fff;
  }

  .bMKyPG_textSummary {
    max-width: 425px;
  }
}

@media screen and (width >= 82rem) {
  .bMKyPG_recommendedProjects {
    padding-left: 132px;
    padding-right: 132px;
  }

  .bMKyPG_headerImageDesktop {
    height: 390px;
  }
}

@media screen and (width >= 100rem) {
  .bMKyPG_recommendedProjects {
    padding-left: 275px;
    padding-right: 275px;
  }
}

.ejjCpW_box {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 40px;
}

.ejjCpW_header {
  height: 80px;
  background-color: var(--color2);
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  padding-left: 20px;
  font: bold 2.2rem / 2.9rem Rubik;
  display: flex;
}

.ejjCpW_body {
  color: #212529;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font: 1.6rem / 2.1rem Karla;
  display: flex;
}

.ejjCpW_inputWrapper {
  width: 100%;
  font: 2.4rem / 2.8rem Karla;
  position: relative;
}

.ejjCpW_input {
  width: 100%;
  height: 65px;
  color: #212529;
  border: 1px solid #70707056;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font: 2.4rem / 2.8rem Karla;
}

.ejjCpW_inputWrapper:after {
  content: "€";
  color: #212529;
  text-align: right;
  margin-top: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
}

.ejjCpW_infoTitle {
  color: #fff;
  font: bold 2.2rem / 2.9rem Rubik;
  display: block;
}

.ejjCpW_info {
  background: var(--color1) 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 20px;
  font: 1.6rem / 2.1rem Karla;
}

@media screen and (width >= 82rem) {
  .ejjCpW_header {
    font-size: 2.4rem;
  }

  .ejjCpW_bigBoxDesktop {
    min-height: 390px;
  }
}

.KsJiPG_box {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 40px;
}

.KsJiPG_box header {
  height: 80px;
  background-color: var(--color2);
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  padding-left: 20px;
  font: bold 2.2rem / 2.9rem Rubik;
  display: flex;
}

.KsJiPG_avatar {
  width: 60px;
  height: 60px;
  background-color: var(--color3);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
  overflow: hidden;
}

.KsJiPG_avatar img {
  min-width: 25px;
  min-height: 25px;
  max-width: 60px;
  max-height: 60px;
}

.KsJiPG_header {
  flex-direction: row;
  padding: 20px;
  display: flex;
}

.KsJiPG_name {
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  display: flex;
}

.KsJiPG_nameText {
  color: #212529;
  text-transform: capitalize;
  font: 800 1.6rem / 2.1rem Karla;
}

.KsJiPG_positionText {
  color: #212529;
  text-transform: capitalize;
  font: italic 1.6rem / 2.1rem Karla;
}

.KsJiPG_body {
  color: #212529;
  border-top: .5px solid #70707026;
  padding: 20px;
  font: 1.6rem / 2.1rem Karla;
}

::placeholder {
  color: #d3d3d3;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #d3d3d3;
}

::-moz-placeholder {
  color: #d3d3d3;
}

.char-count {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

.newproject__container {
  background-color: #f4f6f7;
  flex-direction: column;
  flex: auto;
  align-items: center;
  padding: 0 25px;
  display: flex;
}

.file-upload__preview {
  width: 180px;
  height: 180px;
  background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
  opacity: 1;
  background: #000 no-repeat padding-box padding-box;
  border-radius: 10px;
  position: relative;
}

.file-upload__preview__btn-close {
  cursor: pointer;
  width: 24px;
  height: 24px;
  opacity: 1;
  background: #fff no-repeat padding-box padding-box;
  border: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.file-upload__preview__btn-close img {
  width: 12px !important;
  height: 12px !important;
}

.file-upload__preview img, .file-upload__preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.newproject__title {
  text-align: center;
  margin: 40px 0 45px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 3.6rem;
  font-weight: 700;
}

.newproject__form {
  width: 100%;
  flex-direction: column;
  flex: auto;
  display: flex;
}

.newproject__form .form-group {
  flex-wrap: wrap;
  row-gap: 20px;
  margin-bottom: 20px;
  display: flex;
}

.newproject__form .form-card {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 32px 20px 30px;
  position: relative;
}

.form-card.form-card--file-upload {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  display: flex;
}

.newproject__form .form-card__footnote {
  color: #212529;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  font-style: italic;
}

.newproject__form .form-control {
  color: #212529;
  width: 100%;
  border-radius: 10px;
  padding: 0 40px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
}

.newproject__form textarea.form-control {
  min-height: 144px;
  min-width: 100%;
  max-width: 100%;
  padding: 20px 40px;
  overflow: hidden;
}

.newproject__form .form__label__text {
  color: #00145e4d;
  top: 21px;
  left: 50px;
  font-family: Karla, Roboto, sans-serif !important;
  font-size: 1.8rem !important;
}

.newproject__form .form-button {
  text-transform: uppercase;
  background-color: var(--color2);
  margin: 30px 0 105px;
  padding: 0 40px;
  font-weight: 900;
}

.newproject__form .form-info {
  gap: 25px;
  display: none;
}

.newproject__form .form-info__title {
  color: var(--color1);
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
}

.newproject__form .form-info__description {
  color: #212529;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}

.newproject__form .form-error {
  color: red;
  margin: 15px 10px 0;
  font-size: 1.6rem;
  display: block;
}

@media screen and (width >= 62rem) {
  .newproject__container {
    padding: 0 80px;
  }

  .newproject__title {
    font-size: 6rem;
  }

  .newproject__form .form-group {
    justify-content: flex-end;
    column-gap: 50px;
    margin-bottom: 50px;
  }

  .newproject__form .form-info {
    width: 33.333%;
    flex-direction: column;
    flex-shrink: 0;
    display: flex;
  }

  .newproject__form .form-card, .newproject__form .form-card__footnote {
    width: calc(66.666% - 50px);
  }

  .newproject__form .form-button {
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    margin: 0 0 100px;
  }
}

@media screen and (width >= 82rem) {
  .newproject__container {
    padding: 0 130px;
  }
}

@media screen and (width >= 100rem) {
  .newproject__container {
    padding-left: 275px;
    padding-right: 275px;
  }
}

.DO1npG_container {
  height: 100vh;
  background-color: #f4f6f7;
  justify-content: center;
  align-items: center;
  display: flex;
}

.DO1npG_innerWrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.DO1npG_image {
  width: 200px;
  height: 189px;
  margin-bottom: 30px;
}

.DO1npG_title {
  color: #212529;
  margin-bottom: 30px;
  font: 900 3rem / 4.3rem Rubik;
}

.DO1npG_description {
  color: #212529;
  max-width: 325px;
  text-align: center;
  margin-bottom: 80px;
  font: 2rem / 2.8rem Karla;
  display: block;
}

.DO1npG_button {
  background: var(--color2);
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  padding: 15px 40px;
  font: 900 2rem / 4.3rem Rubik;
  transition: transform .3s;
}

.DO1npG_button:hover {
  transform: scale(1.05);
}

@media screen and (width >= 62rem) {
  .DO1npG_title {
    font: 900 3.6rem / 4.7rem Rubik;
  }

  .DO1npG_description {
    max-width: 542px;
    margin-bottom: 100px;
    font: 2rem / 2.8rem Karla;
  }
}

.x5W5iq_notificationsHeader {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.x5W5iq_notificationsTitle {
  color: #212529;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
}

.x5W5iq_markAsRead {
  width: 100%;
  height: 165px;
  background: var(--color2);
  flex-direction: column;
  margin-left: -25px;
  padding: 29px 36px 0;
  display: flex;
  position: fixed;
  bottom: 0;
}

.x5W5iq_markAsRead button {
  color: #fff;
  cursor: pointer;
  min-height: 60px;
  background: #ffffff4d;
  border: none;
  border-radius: 35px;
  padding: 19px 25px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
}

.x5W5iq_notificationsMenu {
  flex-direction: row;
  column-gap: 25px;
  display: flex;
}

.x5W5iq_notificationsMenu button {
  color: #21252940;
  cursor: pointer;
  background: none;
  border: none;
  font-family: Karla, Roboto, sans-serif;
  font-size: 2rem;
}

.x5W5iq_notificationsMenu button.x5W5iq_active {
  color: var(--color1);
  border-bottom: 3px solid var(--color1);
  font-family: Karla, Roboto, sans-serif;
  font-weight: 700;
}

.x5W5iq_noNotifications {
  flex-direction: column;
  flex: auto;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  display: flex;
}

.x5W5iq_noNotifications img {
  height: 130px;
}

.x5W5iq_noNotifications h2 {
  color: #212529;
  text-align: center;
  margin: 0 42px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

.x5W5iq_notificationsContainer {
  flex-direction: column;
  row-gap: 15px;
  display: flex;
}

.x5W5iq_notificationRow {
  align-items: center;
  column-gap: 45px;
  display: flex;
}

.x5W5iq_dateColumn {
  display: none;
}

.x5W5iq_card {
  background: #fff;
  border-radius: 10px;
  flex-wrap: wrap;
  gap: 25px 20px;
  padding: 25px;
  display: flex;
}

.x5W5iq_thumbnail {
  height: 40px;
  width: 40px;
  background: #000;
  border-radius: 50%;
}

.x5W5iq_content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.x5W5iq_title {
  color: #212529;
  margin-bottom: 10px;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

.x5W5iq_date {
  color: #212529;
  margin-bottom: 20px;
}

.x5W5iq_text {
  color: #21252980;
  font-family: Karla, Roboto, sans-serif;
}

.x5W5iq_time {
  color: #21252980;
  width: 100%;
  justify-content: end;
  font-family: Karla, Roboto, sans-serif;
  display: flex;
}

@media screen and (width >= 62rem) {
  .x5W5iq_notificationsTitle {
    font-size: 3.2rem;
  }

  .x5W5iq_markAsRead {
    width: auto;
    height: auto;
    background: none;
    padding: 0;
    position: relative;
  }

  .x5W5iq_markAsRead button {
    color: var(--color1);
    min-height: auto;
    background: none;
    padding: 0;
    font-size: 1.6rem;
  }

  .x5W5iq_dateColumn {
    width: 25%;
    color: #212529;
    font-weight: 700;
    display: flex;
  }

  .x5W5iq_date {
    display: none;
  }

  .x5W5iq_time {
    width: 25%;
    align-self: flex-end;
  }
}

.LDQfxa_container {
  height: 100vh;
  background-color: var(--color2);
  justify-content: center;
  align-items: center;
  display: flex;
}

.LDQfxa_innerWrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.LDQfxa_errorImage {
  width: 243px;
  height: 120px;
  margin-bottom: 40px;
}

.LDQfxa_title {
  color: #fff;
  margin-bottom: 30px;
  font: 900 3rem / 4.3rem Rubik;
}

.LDQfxa_description {
  color: #fff;
  max-width: 325px;
  text-align: center;
  margin-bottom: 80px;
  font: 2rem / 2.8rem Karla;
  display: block;
}

.LDQfxa_button {
  color: var(--color2);
  text-transform: uppercase;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 100px;
  padding: 15px 40px;
  font: 900 2rem / 4.3rem Rubik;
  transition: transform .3s;
}

.LDQfxa_button:hover {
  transform: scale(1.05);
}

@media screen and (width >= 62rem) {
  .LDQfxa_errorImage {
    width: 404px;
    height: 200px;
    margin-bottom: 50px;
  }

  .LDQfxa_title {
    font: 900 3.6rem / 4.7rem Rubik;
  }

  .LDQfxa_description {
    max-width: 542px;
    margin-bottom: 100px;
    font: 2rem / 2.8rem Karla;
  }
}

/*# sourceMappingURL=index.51d89e02.css.map */
