.navbar__container {
  display: flex;
  flex-direction: column;
}

.navbar {
  position: relative;
  z-index: 100;
  min-height: 75px;
  background-color: white;
  box-shadow: 0px 4px 12px #00145E1A;
  padding: 20px;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.navbar__wrapper {
  z-index: 1000;
  position: sticky;
  top: 0;
}

.btn-lang-selector.btn-lang-selector--trans {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 15px;
}

.navbar__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.navbar__category {
  border: 1px solid var(--color2);
  border-radius: 100px;
  padding: 7px 18px;
  background-color: transparent;
  font: normal normal bold 1.4rem/1.7rem Karla;
  color: var(--color2);
  cursor: pointer;
}

.navbar__category--selected {
  background-color: var(--color2);
  color: white;
}


.navbar__categories {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%;
}

.navbar__categories__filter-btn {
  margin-left: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.navbar.navbar--menu-on {
  background-color: var(--color2);
  position: sticky;
  top: 0;
  width: 100%;
  box-shadow: none;
}
.navbar__right-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.navbar__search-box {
  position: static;
  display: flex;
  align-items: center;
  width: calc(100% - 25px - 25px) !important;
}
.navbar__logo {
  /* position: absolute;
  left: 25;
  top: 0;
  bottom: 0;
  margin: auto 0; */
  width: 150px;
  height: 30px;
}
.navbar__search-input {
  font-family: Karla, Roboto, sans-serif;

  color: rgba(33, 37, 41, 0.25);
  font-size: 2rem;
  border: none;
  border-radius: 10px;
}
.navbar__search-input:focus {
  outline: none;

}
.navbar__magnifier {
  pointer-events: none;
  margin-right: 35px;
}
.navbar__magnifier.navbar__magnifier--nomargin {
  margin-right: 15px;
}
.navbar__ham {
  pointer-events: none;
}
.navbar__ham--right {
  margin-left: auto;
}
.navbar__search-button {
  border: none;
  background: none;
  cursor: pointer;
}
.navbar__dropdown-desktop {
  position: absolute;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00145E1A;
  bottom: -185;
  right: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 25px;
  padding-bottom: 25px;
}

.navbar__dropdown-destkop__separator {
  width: 100%;
  height: 1px;
  background-color: #E5E5E5;
}
.navbar__dropdown-desktop button {
  margin-left: 25px;
  margin-right: 25px;
  font: normal normal normal 1.4rem/1.7rem Lato;
  color: #212529;
  background-color: white;
  border: none;
  width: max-content;
  display: inline-block;
  text-align: center;
  cursor: pointer;

}
.navbar__dropdown {
  position: fixed;
  width: 100%;
  height: 0px;
  min-height: 0px;
  transition: min-height 0.4s ease-in-out;
  overflow: auto;
}
.navbar__dropdown.navbar__dropdown--active {
  padding-top: calc(5px + 75px);
  background-color: white;
  min-height: 100vh;
  padding-bottom: 185px;
  top: 0;
}

.navbar__dropdown__footer {
  position: fixed;
  bottom: 0;
  height: 165px;
  width: 100%;
  background-color: var(--color2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  pointer-events: none;
}

.navbar__dropdown_footer__btn {
  color: var(--color2);
  background-color: white;
  padding: 15px 30px 15px 30px;
  border-radius: 100px;
  border: none;
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 21px;
  cursor: pointer;
}

.navbar__dropdown_footer__btn--filters {
  font-family: Rubik, Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  background-color: var(--color3);
  padding: 20px 70px 20px 70px;
  color: white;
  border: none;
  border-radius: 100px;
}

.navbar__dropdown_footer__btn--logout {
  color: white;
  font-family: Karla, Roboto, sans-serif;
  font-weight: 700;
  background: none;
  font-size: 1.7rem;
  border: none;
  cursor: pointer;
}

.navbar__dropdown-item {
  font-family: Karla, Roboto, sans-serif;
  color: #212529;
  font-weight: 700;
  font-size: 1.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 68px;
  width: 100%;
  background-color: white;
  border: none;
  padding: 0px 24px 0px 24px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  cursor: pointer;
}

.navbar__dropdown-items-wrapper {
  position: relative;
  opacity: 1;
}

.navbar__dropdown-arrow {
  margin-left: auto;
  margin-right: 5px;
}

.navbar__myaccount-btn {
  border: none;
  background-color: var(--color2);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  overflow: hidden;
}

.navbar__myaccount-btn img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.navbar__myaccount-btn img.svg-loaded {
  pointer-events: none;
  height: 16px;
  width: 16px;
}

.navbar__notifications-btn {
  background: none;
  margin-right: 25px;
  border: none;
  cursor: pointer;
  padding: 0;
}
.navbar__notifications-btn object {
  pointer-events: none;
  margin-top: 2px;
}

.navbar__option {
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  color: rgba(33, 37, 41, 0.25);
  border-bottom: 2px solid transparent;
  transition: all .5s;
}

.navbar__option:hover {
  border-bottom: 2px solid var(--color2);
}

.navbar__option:hover a {
  color: var(--color2);
}

.navbar__option a {
  color: rgba(33, 37, 41, 0.25);
  text-decoration: none;
  transition: all .5s;
}

.navbar__option.navbar__option--selected {
  border-bottom: 2px solid var(--color2);
}

.navbar__option.navbar__option--selected a {
  color: var(--color2);
}

.navbar__options-wrapper {
  margin-left: 30px;
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: 32px;
}

.navbar__option:last-child {
  background-color: var(--color2);
  padding: 10px 14px;
  border-radius: 100px;
  border: none;
}

.navbar__option:last-child a {
  color: #FFFFFF;
}

@media screen and (min-width: 62rem) {
  .navbar__wrapper {
    position: relative;
  }
  .navbar__magnifier {
    margin-right: 25px;
  }
  .navbar {
    padding: 0 80px 0 80px;
  }
  .navbar.navbar--menu-on {
    background-color: white;
    position: relative;
  }
  .navbar__wrapper-mb {
    padding-top: 75px;
  }
  .navbar__inner--pt {
    min-height: 75px;
  }
}

@media screen and (min-width: 82rem) {
  .navbar {
    padding: 0 132px 0 132px;
  }
}

@media screen and (min-width: 100rem) {
  .navbar {
    padding: 0 275px 0 275px;
  }
}
