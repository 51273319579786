
.legal-content {
  display: flex;
  background-color: #F4F6F7;
  padding: 56px 25px;

  flex-direction: column;
  align-items: center;

}
.legal-content h1 {
  font: normal normal bold 36px/47px Rubik;
  color: #212529;
  margin-bottom: 23px;
  text-align: center;
  margin-bottom: 80px;
}

.legal-content h2 {
  font: normal normal bold 24px/31px Rubik;
  color: #212529;
  margin-bottom: 25px;
}
.legal-section {
  width: 100%;
}
.legal-content .legal-section {
  margin-bottom: 80px;
}
.legal-content .legal-section ul:last-child {
  margin-bottom: 0;
}
.legal-content .legal-section span:last-child {
  margin-bottom: 0;
}

.legal-content span {
  display: block;
  font: normal normal normal 18px/23px Karla;
  color: #212529;
  margin-bottom: 25px;
}

.legal-content ul {
  font: normal normal normal 18px/23px Karla;
  color: #212529;
  padding-left: 15px;
  margin-bottom: 25px;
}

.legal-icon {
  width: 91px;
  height: 100px;
  margin-bottom: 35px;
}


.legal-footer {
  background-color: transparent;
  border-top: 1px solid #FFFFFF;
}

.legal-footer.legal-footer--color {
  background-color: var(--color1);
  border: none;
}

.legal-footer.legal-footer--blue {
  background-color: #39ADC1;
  border: none;
}
.legal-footer span {
  
  color: #FFFFFF;
  padding-top: 25px;
  padding-bottom: 25px;
  display: block;
  text-align: center;
  font: normal normal normal 16px/21px Karla;
}

@media screen and (min-width: 62rem) {
  .legal-content {
    padding: 50px 139px 0;
  }
}


@media screen and (min-width: 100rem) {
  .legal-content {
    padding-top: 50px;
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
}

@media screen and (min-width: 110rem) {
  .legal-content {
    padding-top: 50px;
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
}