.reset-container {
  margin-top: 15px;
}
.reset-container.reset-container--success {
  position: absolute;
  padding-right: 25px;
}
.reset-title {
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 700;
  font-size: 2.4rem;
  color: #212529;
}
.reset-footer {
  margin-top: 54px;
  display: flex;
  align-items: center;
  flex-direction: column;
  
  border-top: 1px solid rgba(112, 112, 112, 0.20);
  width: 100%;
  padding-top: 30px;
}
.reset-page-desc {
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  color: rgba(33, 37, 41, 0.50);
  margin-bottom: 51px;
  display: inline-block;
  margin-top: 30px;
  font-weight: 100;
}
.reset-footer__text {
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  display: block;
  text-align: center;
  color: rgba(33, 37, 41, 0.50);
  width: 100%;
  margin-bottom: 20px;
  width: 26.2rem;
}
.reset-footer__link {
  color: var(--color2);
  text-decoration: none;
  font-family: Karla, Roboto, sans-serif;
  font-weight: 700;
  font-size: 2rem;
  display: block;
  
}

.reset-icon {
  margin-bottom: 52px;
}


@media screen and (min-width: 43rem) {
  .reset-container.reset-container--success {
    padding-right: 50px;
  }
}
@media screen and (min-width: 62rem) {
  .reset-footer {
    margin-top: 35px;
  }
  .reset-title {
    font-size: 3rem;
  }
  .reset-icon {
    margin-bottom: 39px;
  }
}