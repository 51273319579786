.panel-container {
  display: flex;
  flex: auto;
  background-color: #F4F6F7;
  flex-direction: column;
  padding: 0 25px;
}

.panel-menu {
  display: flex;
  flex-direction: column;
  min-width: 264px;
  margin-top: 40px;
}

.panel-menu__title {
  display: none;
}

.panel-menu__options {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  height: 55px;
  transition: height 0.3s ease-in-out;
}

.panel-menu__options button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 21px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  width: 100%;
  padding: 0 20px;
  color: #212529;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  height: 55px;
  flex: 0 0 auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.panel-menu__options.panel-menu__on {
  height: calc(55px * 4);
}

.panel-menu__options.panel-menu__on button {
  opacity: 1;
}

.panel-menu__options button.active {
  background: url('/src/assets/icons/perfil_desplegable_down_smartphone.svg') right center no-repeat content-box;
  order: -1;
  opacity: 1;
}

.panel-menu__options.panel-menu__on button.active {
  background-image: url('/src/assets/icons/perfil_desplegable_up_smartphone.svg');
}

.panel-menu__options button:hover.active {
  background-color: initial;
}

.panel-menu__options button:hover {
  background-color: var(--color3);
}



.panel-menu__options button > div {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center center;
}

.panel-menu__options button > div.ico-account {
  background-image: url(/src/assets/icons/compte_perfil.svg);
}

.panel-menu__options button > div.ico-history {
  background-image: url(/src/assets/icons/compte_historial.svg);
}

.panel-menu__options button > div.ico-saved {
  background-image: url(/src/assets/icons/compte_projectes_guardats.svg);
}

.panel-menu__options button > div.ico-help {
  background-image: url(/src/assets/icons/compte_ajuda.svg);
}

.panel-selected-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.page-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.page-container__title {
  color: #212529;
  font-family: Rubik, Roboto, sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.page-container__subtitle {
  color: #212529;
  font-family: Karla, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 30px;
}

.page-card {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 10px;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 50px;
}

.fade-hide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.fade-show {
  animation: fade 0.5s ease-in-out;
  opacity: 1;
  pointer-events: all;
}

@media screen and (min-width: 62rem) {
  .panel-container {
    flex-direction: row;
    padding: 0 132px;
    gap: 93px
  }
  .panel-menu__title {
    display: block;
    margin-bottom: 21px;
    color: #cacaca;
    font-family: Karla, Roboto, sans-serif;
    font-size: 1.4rem;
  }
  .panel-menu {
    margin-top: 73px;
  }

  .panel-menu__options {
    height: auto;
  }

  .panel-menu__options button {
    padding: 0 16px;
    height: 55px;
    opacity: 1;
  }

  .panel-menu__options button.active,
  .panel-menu__options button:hover.active {
    background: var(--color2) !important;
    color: #FFFFFF;
    order: 0;
  }

  .panel-menu__options button.active > div {
    background: #FFF;
  }

  .panel-menu__options button.active > div.ico-account {
    mask: url(/src/assets/icons/compte_perfil.svg) center center no-repeat;
    -webkit-mask: url(/src/assets/icons/compte_perfil.svg) center center no-repeat;
  }

  .panel-menu__options button.active > div.ico-history {
    mask: url(/src/assets/icons/compte_historial.svg) center center no-repeat;
    -webkit-mask: url(/src/assets/icons/compte_historial.svg) center center no-repeat;
  }

  .panel-menu__options button.active > div.ico-saved {
    mask: url(/src/assets/icons/compte_projectes_guardats.svg) center center no-repeat;
    -webkit-mask: url(/src/assets/icons/compte_projectes_guardats.svg) center center no-repeat;
  }

  .panel-menu__options button.active > div.ico-help {
    mask: url(/src/assets/icons/compte_ajuda.svg) center center no-repeat;
    -webkit-mask: url(/src/assets/icons/compte_ajuda.svg) center center no-repeat;
  }

  .panel-selected-page {
    margin-top: 60px;
    overflow: auto;
  }
}

@media screen and (min-width: 100rem) {
  .panel-container {
    padding-left: 275px;
    padding-right: 275px;
  }
}