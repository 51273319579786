.landing__container {
  scroll-behavior: smooth;
}


.landing__top-section {
  padding: 64px 25px 0px 20px;
  background-color: #55C8DC;
  z-index: 10;
}
.hide {
  display: none;
}

.landing__top-section h1 {
  font: normal normal 900 36px/46px Rubik;
  color: #FFFFFF;
  margin-bottom: 40px;
  display: block;
}
.landing__top-section__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: -2px;
  z-index: 100;
  background-color: #55C8DC;
  padding: 20px 25px 0px 25px;
  transition: all 0.5s ease, padding-top 0.5s ease-in-out;
}

.landing__top-section__bar-stucked {
  display: flex;
  padding-top: 0px;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 12px #00145E1A;
}

.landing__top-space-between {
  display: flex;
  flex-direction: column;
  margin-bottom: 86px;
}
.landing__top-space-between .intro {
  margin-bottom: 80px;
}

.btn-lang-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 8px;
  padding-right: 8px;
  height: 36px;
  width: 48px;
  font-weight: 700;
  background: none;
  border-radius: 10px;
  border: 2px solid #FFFFFF;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  display: none;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.btn-lang-selector.active {
  visibility: visible;
  display: flex;
  pointer-events: all;
  opacity: 1;
}


.btn-lang-selector.stucked {
  border: 2px solid #212529;
  color: #212529;
}

.btn-lang-selector > img {
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  padding-top: 2px;
}

.btn-lang-cross-mobile {
  background: url('../../../assets/icons/icono_cierre_menu_neg.svg') no-repeat;
  height: 24px;
  width: 24px;
  border: none;
  cursor: pointer;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
}
.btn-lang-cross-mobile.active {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  display: block;
}


.btn-lang-cross-mobile.stucked {
  background: url('/src/assets/icons/icono_cierre_menu.svg') no-repeat;
}

.landing__top-section__bar.landing__top-section__bar-stucked .btn-lang__dropdown {
  top: 100%;
}

.landing__top-space-between picture {
  display: flex;
  justify-content: center;
}
.landing__top-space-between img {
  width: 224px;
  height: 230px;
  transform: scaleX(-1);
}

.landing__top-space-between .intro span {
  font: normal normal normal 22px/28px Karla;
  color: #FFFFFF;
  max-width: 444px;
  display: block;
}

.landing__top-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.landing__top-center h2 {
  text-align: center;
  font: normal normal 900 24px/32px Rubik;
  color: #FFFFFF;
  display: block;
  max-width: 300px;
  margin-bottom: 39px;
}
.landing__top-center span {
  text-align: center;
  font: normal normal normal 22px/28px Karla;
  color: #FFFFFF;
  max-width: 713px;
  margin-bottom: 80px;
}
.landing__top-center > img {
  display: none;
  margin-bottom: 14px;
  width: 31px;
  height: 34px;
  animation: pulse 2.5s infinite;
}
.landing-fake-picture {
  width: 375px;
  height: 247px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.landing__top-center .mockup-wrapper {
  margin-bottom: -4px;
  bottom: 0;
  margin-left: -25px;
  margin-right: -25px;
}

.landing__top-center picture {
  height: auto;
  z-index: 10;
}
.landing__top-center picture > img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: auto;
}

.landing__body-section {
  position: relative;
  z-index: 20;
  padding: 72px 25px 0px 25px;
  background-color: #FFFFFF;
}
.landing__body-section .intro {
  display: flex;
  flex-direction: column;
}
.landing-boxes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 72px;
  gap: 72px;
}
.landing-box {
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 24px 32px #00145E0F;
  border-radius: 10px;
  width: auto;
  min-height: 282px;
  padding: 72px 24px 24px;
}

.landing-box span {
  display: block;
  color: #212529;
  text-align: left;
  font: normal normal bold 24px/28px Karla;
  margin-bottom: 16px;
}
.landing-box span:last-child {
  font: normal normal normal 17px/22px Karla;
  color: #2125297c;
  margin-bottom: 0px;
}

.landing-box img{
  width: 80px;
  height: 80px;
  position: absolute;
  top: -40;
  left: 25;
}

.landing__body-section .intro {
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(96px + 40px);
}

.landing__body-section .intro h2 {
  margin-bottom: 40px;
  color: #212529;
  font: normal normal 900 36px/43px Rubik;
  max-width: 224px;
}
.landing__body-section .intro span {
  font: normal normal normal 22px/28px Karla;
  letter-spacing: 0px;
  color: #2125297a;
  max-width: 625px;
}

.video-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 403px;
}

.video-section .video-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 64px;
}
.video-intro span {
  display: block;
  font: normal normal normal 22px/28px Karla;
  color: #2125297e;
  max-width: 275px;
}

.video-intro h2 {
  text-align: center;
  font: normal normal 900 36px/43px Rubik;
  color: #212529;
  margin-bottom: 42px;
  max-width: 256px;
}
.landing-president-quote {
  margin-top: -2px;
  margin-left: -25px;
  margin-right: -25px;
  display: flex;
  flex-direction: column;
  background-color: #55C8DC;
}
.landing-president-quote picture {
  width: 100%;
  height: 333px;
  display: flex;
  justify-content: center;
}
.footer-picture-wrapper {
  display: flex;
  justify-content: center;
}
.landing-president-quote picture img {
  width: auto;
  height: 100%;
}
.landing-president-quote span.big-quote {
  position: relative;
  font: italic normal normal 24px/32px Karla;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom: 56px;
  max-width: 406px;
}
.landing-president-quote span.big-quote::before {
  content: url('/src/assets/icons/comilla_01.svg');
  position: absolute;
  top: -20;
  left: -10;
}
.landing-president-quote span.big-quote::after {
  content: url('/src/assets/icons/comilla_02.svg');
  position: absolute;
  right: 0;
  bottom: -5;
}
.landing-president-quote span {
  display: block;
}

.big-quote-wrapper {
  padding: 48px 25px 64px 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.big-quote-wrapper > div {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.big-quote-wrapper .big-quote__president-name {
  color: #FFFFFF;
  font-size: 16px;
  align-self: end;
}
.big-quote-wrapper .big-quote__president-name strong {
  color: #FFFFFF;
  font-size: 16px;
  font: normal normal bold 20px/24px Karla;
  align-self: end;
}


.landing-videoplayer {
  width: 100%;
  border-radius: 0px;
}

.landing-footer {
  background-color: #212529;
  color: #FFFFFF;
  padding: 0;
  overflow: hidden;
}
/* min-height: 732px; */


.landing-footer section {
  margin-top: 80px;
  border-top: 1px solid #FFFFFF;
  font: normal normal normal 16px/21px Karla;
  color: #FFFFFF;
  padding-top: 34px;
  padding-bottom: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-footer .landing-footer__inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: 72px 25px 0px 25px;
  margin-bottom: 80px;
}

.landing__top-section__bar div.youdrop-logo.youdrop-logo--long-blue {
  width: 151px !important;
  height: 30px !important;
}
.landing-footer .landing-footer__inner > div {
  padding-top: 50px;
}
.landing-footer .landing-footer__inner div form {
  width: 100%;
}

.landing-footer .landing-footer__inner > div h2 {
  font: normal normal 900 36px/43px Rubik;
  color: #FFFFFF;
  margin-bottom: 42px;
}
.btn .btn-primary .btn--email span {
  display: none;
}
.landing-footer .landing-footer__inner > div > span {
  margin-bottom: 56px;
  display: block;
  font: normal normal normal 22px/28px Karla;
  max-width: 442px;
  color: #FFFFFF;
}
.landing-footer__inner > picture {
  display: flex;
  justify-content: center;
}
.footer-picture > img {
  width: 240px;
  height: 203px;
}


.landing-footer__email-wrapper {
  position: relative;
  width: 100%;
  height: 64px;
  border-radius: 100px;
}
.landing-footer__email-wrapper input:focus {
  outline: none;
}
.btn.btn-primary.btn--email.btn--email--error {
  background: url('/src/assets/icons/icono_error.svg') no-repeat;
  text-indent: 100px;
  width: 64px;
  height: 64px;
  min-width: 64px;
  bottom: 0;
  margin-bottom: -3px;
  margin-right: -7px;
}

.landing-footer__email-wrapper input {
  height: 100%;
  width: 100%;
  border-radius: 100px;
  border: none;
  position: absolute;
  font: normal normal normal 18px/22px Karla;
  color: #2125297d;
  padding-left: 32px;
}


.landing-footer__email-wrapper button > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing-footer__email-wrapper button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 900 22px/43px Rubik;
  text-transform: uppercase;
  right: 0;
  height: 100%;
  border-radius: 100px;
  border: none;
  background-color: #39ADC1;
  color: #FFFFFF;
  cursor: pointer;
}

.video-landing-container {
  width: 945px;
  height: 561px;
}

.landing-fakevideo-wrapper {
  margin-left: -25px;
  margin-right: -25px;
  position: relative;
  cursor: pointer;
  height: fit-content;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-fakevideo-wrapper > div {
  width: 100%;
}

.landing-fakevideo-wrapper .landing-play-btn {
  position: absolute;
  pointer-events: none;
}
.btn.btn-follow-us {
  background-color: white;
  border: none;
  color: #40c0d6;
  padding: 20px 42px;
  border-radius: 100px;
  text-transform: uppercase;
  font: 900 24px / 43px Rubik;
  min-width: 173px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin-top: 60px;
  width: 100%;
}
.btn-follow-us-link {
  /* remove underline */
  width: auto;
  display: block;
  text-decoration: none;
}
.btn.btn-follow-us:hover {
  background-color: rgb(239, 239, 239);
}
.btn.btn--email {
  transition: background-color 0.3s ease;
  transition: all 0.3s ease;
}
.btn.btn--email:hover {
  background-color: #40c0d6;
}
.btn.btn--email:disabled {
  background-color: #6f6f6f;
  cursor: not-allowed;
}
.landing-footer__email-wrapper--error {
  border: 3px solid #FF0000;
}
.landing-footer__inner div span.landing-email__error {
  font: normal normal normal 18px/22px Karla;
  color: #FF0000;
  margin-top: 16px;
  margin-bottom: 0px;
}

.landing-footer__inner div .email-sub-box {
  position: absolute;
  padding: 24px;
  border-radius: 10px;
  background-color: white;
  color: #2125297c;
  height: 155px;
  animation: crushed 0.5s ease-in-out;
}

@keyframes crushed {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.landing-footer__inner div .email-sub-box span {
  font: normal normal normal 22px/28px Karla;
  color: #2125297c;
}

.landing-footer__inner div .email-sub-box span.email-sub-box__title {
  font: normal normal 900 24px/32px Rubik;
  color: #212529;
  margin-bottom: 16px;
}
.landing-footer__inner div .email-sub-box img {
  position: absolute;
  top: -25;
  left: -20;
  animation: expand 0.5s ease;
}
@keyframes expand {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}


.btn-lang__dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 125%;
  z-index: 50;
  left: 0;
  right: 0;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00145E1A;
}

.btn-lang__dropdown button {
  border: none;
  background: none;
  cursor: pointer;
  padding-top: 22px;
  padding-bottom: 25px;
  border-bottom:rgb(239, 239, 239, 0.9) 1px solid;
  width: 100%;
  font: normal normal bold 20px/23px Karla;
  letter-spacing: 0px;
  color: #212529;
}
.btn-lang__dropdown button.selected {
  background-color: #CBEEF5;
}
.btn-lang__dropdown button img {
  margin-right: 9px;
}
.btn.btn-primary.btn--email span {
  display: none;
}

.footer-separator {
  border-top: 1px solid #FFFFFF;
  padding: 34px 25px;
  display: flex;
  justify-content: space-between;
}

.footer-separator a {
  color: #FFFFFF;
  font: normal normal normal 16px/21px Karla;
  text-decoration: none;
}

@media screen and (min-width: 62rem) {
  .btn-lang__dropdown button.selected {
    background-color: white;
  }
  .btn-lang__dropdown button > img {
    display: none;
  }
  .btn-lang-cross-mobile.active {
    display: none;
    pointer-events: none;
  }
  .btn-lang-wrapper {
    position: relative;
  }
  .btn-lang-selector {
    display: flex;
    visibility: visible;
    pointer-events: all;
    opacity: 1;
  }
  .btn-lang-cross-mobile {
    display: none;
  }
  .btn-lang__dropdown {
    width: 98px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    position: absolute;
    top: 130%;
    z-index: 50;
    left: -5px;
    padding: 25px;
  }

  .btn-lang__dropdown button {
    font: normal normal normal 14px/17px Lato;
    color: #212529;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    text-align: left;
  }

  .btn-lang-selector > img {
    display: block;
  }
  .video-section {
    flex-direction: row;
    margin-bottom: 160px;
    column-gap: 50px;
    align-items: center;
  }
  .video-intro h2 {
    text-align: left;
  }
  .landing__top-space-between .intro {
    margin-bottom: 48px;
  }
  .landing__top-section__bar div.youdrop-logo.youdrop-logo--long-blue {
    width: 111px !important;
    height: 22px !important;
  }
  .landing-box {
    max-width: 339px;
    height: auto;
  }
  .landing__top-section__bar.landing__top-section__bar-stucked {
    padding-top: 0px;
  }
  .landing-footer .landing-footer__inner {
    flex-direction: row;
  }
  .landing-footer .landing-footer__inner div form {
    width: auto;
  }

  .footer-picture {
    display: flex;
  }
  .footer-picture > img {
    max-width: 515px;
    max-height: 436px;
    width: 100%;
    height: auto;
  }
  .landing-footer__email-wrapper {
    width: 440px;
  }

  .landing-footer section {
    margin-top: 80px;
  }

  .big-quote-wrapper .big-quote__president-name strong {
    font: normal normal bold 22px/24px Karla;
  }
  .landing-president-quote span {
    display: block;
  }
  .big-quote-wrapper {
    flex: 1 1 auto;
    padding: 94px 65px;
    display: flex;
  }
  .landing-president-quote span.big-quote::before {
    top: -55;
    left: -30;
  }
  .landing-president-quote span.big-quote::after {
    right: -20;
    bottom: -25;
  }
  .landing-president-quote {
    margin: 0 0 160px;
    border-radius: 10px;
    width: 100%;
    height: 478px;
    flex-direction: row;
    overflow: hidden;
  }
  .landing-president-quote picture {
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
    margin: 0;
    overflow: hidden;
    max-width: fit-content;
  }
  .landing-footer__email-wrapper button {
    min-width: 173px;
  }
  .landing-footer__email-wrapper button > div {
    display: block;
  }
  .btn.btn-primary.btn--email div {
    display: none;
  }
  .btn.btn-primary.btn--email span {
    display: inline-block;
  }
  .landing-fakevideo-wrapper {
    margin: 0;
  }
  .landing-videoplayer {
    max-width: 713px;
    border-radius: 10px;
  }
  .video-section .video-intro {
    display: block;
    text-align: left;
    margin-bottom: 0px;
  }
  .landing-boxes {
    flex-direction: row;
    gap: 72px 25px;
    margin-bottom: 159px;
  }
  .landing__body-section .intro h2 {
    margin-bottom: 0px;
  }
  .landing__body-section .intro {
    display: block;
  }
  .landing__top-center > picture {
    width: auto;
    height: auto;
  }
  .landing-fake-picture {
    width: 895px;
    height: 589px;
  }
  .landing__top-center > img {
    display: block;
  }
  .landing__body-section {
    padding: 96px 139px 0px 139px;
  }
  .landing__top-section__bar {
    padding: 50px 139px 0px 139px;
  }
  .landing__top-space-between .intro {
    margin-bottom: 48px;
  }

  .landing__top-section h1 {
    font: normal normal 900 60px/72px Rubik;
    margin-bottom: 50px;
  }

  .landing__top-space-between {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0px;
  }
  .landing__top-space-between img {
    width: 472px;
    height: 486px;
  }
  .btn.btn-follow-us {
    width: auto;
  }

  .landing__top-section {
    padding: 70px 139px 0px 139px;
  }
  .landing-footer {
    padding: 88px 140px 0px 140px;
  }
}

@media screen and (min-width: 100rem) {
  .landing__body-section {
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
  .landing__top-section__bar {
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
  .landing__top-section {
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
  .landing-footer {
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
}
@media screen and (min-width: 110rem) {
  .landing__body-section {
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
  .landing__top-section__bar {
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
  .landing__top-section {
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
  .landing-footer {
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
}
