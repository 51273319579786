/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('./fonts/Rubik-LightItalic.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('./fonts/Rubik-LightItalic.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('./fonts/Rubik-LightItalic.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: local('Rubik Light Italic'), local('Rubik-LightItalic'), url('./fonts/Rubik-LightItalic.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: local('Rubik Italic'), local('Rubik-Italic'), url('./fonts/Rubik-Italic.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: local('Rubik Italic'), local('Rubik-Italic'), url('./fonts/Rubik-Italic.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: local('Rubik Italic'), local('Rubik-Italic'), url('./fonts/Rubik-Italic.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: local('Rubik Italic'), local('Rubik-Italic'), url('./fonts/Rubik-Italic.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'), url('./fonts/Rubik-MediumItalic.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'), url('./fonts/Rubik-MediumItalic.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'), url('./fonts/Rubik-MediumItalic.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'), url('./fonts/Rubik-MediumItalic.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('./fonts/Rubik-BoldItalic.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('./fonts/Rubik-BoldItalic.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('./fonts/Rubik-BoldItalic.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'), url('./fonts/Rubik-BoldItalic.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  src: local('Rubik Black Italic'), local('Rubik-BlackItalic'), url('./fonts/Rubik-BlackItalic.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  src: local('Rubik Black Italic'), local('Rubik-BlackItalic'), url('./fonts/Rubik-BlackItalic.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  src: local('Rubik Black Italic'), local('Rubik-BlackItalic'), url('./fonts/Rubik-BlackItalic.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  src: local('Rubik Black Italic'), local('Rubik-BlackItalic'), url('./fonts/Rubik-BlackItalic.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: local('Rubik Light'), local('Rubik-Light'), url('./fonts/Rubik-Light.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: local('Rubik Light'), local('Rubik-Light'), url('./fonts/Rubik-Light.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: local('Rubik Light'), local('Rubik-Light'), url('./fonts/Rubik-Light.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: local('Rubik Light'), local('Rubik-Light'), url('./fonts/Rubik-Light.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), url('./fonts/Rubik-Regular.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), url('./fonts/Rubik-Regular.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), url('./fonts/Rubik-Regular.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), url('./fonts/Rubik-Regular.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('./fonts/Rubik-Medium.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('./fonts/Rubik-Medium.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('./fonts/Rubik-Medium.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('./fonts/Rubik-Medium.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local('Rubik Bold'), local('Rubik-Bold'), url('./fonts/Rubik-Bold.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local('Rubik Bold'), local('Rubik-Bold'), url('./fonts/Rubik-Bold.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local('Rubik Bold'), local('Rubik-Bold'), url('./fonts/Rubik-Bold.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local('Rubik Bold'), local('Rubik-Bold'), url('./fonts/Rubik-Bold.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: local('Rubik Black'), local('Rubik-Black'), url('./fonts/Rubik-Black.woff2') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: local('Rubik Black'), local('Rubik-Black'), url('./fonts/Rubik-Black.woff2') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: local('Rubik Black'), local('Rubik-Black'), url('./fonts/Rubik-Black.woff2') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: local('Rubik Black'), local('Rubik-Black'), url('./fonts/Rubik-Black.woff2') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* karla-regular - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Karla-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}



/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Lato-Regular.ttf') format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Lato-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
