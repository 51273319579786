.password-box {
  max-height: 0;
  transition: max-height .5s, opacity .5s ease-in-out;
  overflow: hidden;
  background-color: var(--color2);
  border-radius: 10px;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 13px;
  padding-bottom: 5px;
  opacity: 0;
}

.password-box--show { 
  opacity: 1;
  max-height: 500px;
  transition: max-height .5s, opacity .5s ease-in-out;
  width: 100%;
  margin-bottom: 15px;
}


.password-box__info {
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
  color: #ffffff;
}

.password-box__badges-wrapper {
  padding-top: 10px;
}
.password-box__badge {
  vertical-align: middle;
  font-family: Karla, Roboto, sans-serif;
  text-align: center;
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 8px;
  background-color: white;
  border-radius: 23px;
  font-size: 1.6rem;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.password-box__check-icon {
  display: inline;
  margin-right: 8px;
}

.register-success-container {
  margin-top: 58px;
}
.register-success-desc {
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
  color: rgba(33, 37, 41, 0.50);
  margin-bottom: 40px;  
  display: inline-block;
  margin-top: 40px;
}

@media screen and (min-width: 62rem /* desktop 992px */) {
  .register-success-container {
    margin-top: 110px;
  }
}
