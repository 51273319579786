@import './../../public/fonts.css';

body, html {
  font-family: 'Rubik','Roboto', sans-serif;
  font-size: 62.5%;
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container-app {
  background-color: var(--color4);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 40px;
  padding-bottom: 100px;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: auto;
}

.youdrop-logo {
  width: 11.5rem;
  height: 11.8rem;
  background-image: url('../assets/logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.youdrop-logo.youdrop-logo--white {
  width: 4.8rem;
  height: 5rem;
  filter: brightness(0) invert(1);
}

.youdrop-logo--top {
  width: 4.8rem;
  height: 5rem;
  position: absolute;
  top: 30;
  left: 25;
}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.separator--15w {
  width: 15px;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.loading-container {
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  height: 100%
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgba(var(--rgbcolor), 0.3);
  border-color: rgba(var(--rgbcolor), 0.3) transparent rgba(var(--rgbcolor), 0.3) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}


input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}

.title-h1 {
  font: normal normal bold 2.8rem/3.6rem Rubik;
  color: #212529;
  letter-spacing: 0px;
  margin-bottom: 20px;
}

.paragraph {
  display: block;
  font: normal normal normal 1.6rem/2.1rem Karla;
  color: #212529;
}

.small-circles-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}
.small-circle {
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 100px;
  display: inline-block;
}

.small-circle--animation1 {
  animation: small-circle-animation1 1.2s infinite;
  animation-delay: 0.2s;
}
.small-circle--animation2 {
  animation: small-circle-animation1 1.2s infinite;
  animation-delay: 0.4s;
}
.small-circle--animation3 {
  animation: small-circle-animation1 1.2s infinite;
  animation-delay: 0.6s;
}

@keyframes small-circle-animation1 {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.youdrop-logo.youdrop-logo--long-blue {
  background-image: url('../assets/logo_long_blue.svg');
  width: 11.5rem !important;
  height: 2.2rem !important;
}

.flex-section {
  display: flex;
  flex-direction: column;
}

.max-w-330 {
  max-width: 330px;
}

.file-upload__droparea {
  color: #212529;
  font-family: Karla, Roboto, sans-serif;
  font-size: 2.2rem;
  text-align: center;
  padding: 35px 0;
  border: 1px solid rgba(0, 20, 94, 0.3);
  border-radius: 10px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;
}

.file-upload__droparea--dragging {
  border: 1px solid var(--color2);
  box-shadow:inset 0px 0px 0px 2px var(--color2);
}

.file-upload__droparea.active{
  border: 1px solid #0389B2;
  box-shadow:inset 0px 0px 0px 2px #0389B2;
}

.file-upload__fileinput {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.file-upload__droparea label{
  display: inline;
}

.file-upload__droparea label a{
  color: var(--color1);
  text-decoration: underline;
  font-weight: 700;
}

.file-upload__droparea span {
  display: block;
  color: rgba(33, 37, 41, 0.25);
  font-size: 1.8rem;
  margin-top: 15px;
}

@media screen and (min-width: 62rem) {
  .container-app {
    padding-top: 60px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .flex-section {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .title-h1 {
    font: normal normal bold 3.2rem/4.2rem Rubik;
    color: #212529;
    margin-bottom: 15px;
  }

  .paragraph {
    display: block;
    font: normal normal normal 1.6rem/2.1rem Karla;
    color: #212529;
  }
  .youdrop-logo {
    width: 15rem;
    height: 15.2rem;
  }
  .youdrop-logo.youdrop-logo--top {
    top: 50;
    left: 60;
  }
  .youdrop-logo.youdrop-logo--long {
    width: 25.1rem;
    height: 5rem;
  }
  .youdrop-logo.youdrop-logo--white.youdrop-logo--long {
    background-image: url('../assets/logo_long.png');
  }
  .youdrop-logo.youdrop-logo--blue.youdrop-logo--long {
    background-image: url('../assets/logo_long_blue.svg');
  }
}

@media screen and (min-width: 82rem) {
  .flex-section {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
  .max-w-330 {
    max-width: 450px;
  }
  .title-h1 {
    font: normal normal bold 3.2rem/4.3rem Rubik;
  }

  .youdrop-logo.youdrop-logo--top {
    top: 50;
    left: 120;
  }
  .container-app {
    padding-left: 132px;
    padding-right: 132px;
  }
}
@media screen and (min-width: 100rem) {
  .youdrop-logo.youdrop-logo--top {
    top: 50;
    left: 200;
  }
  .container-app {
    padding-left: 275px;
    padding-right: 275px;
  }
}