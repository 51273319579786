.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.modal-wrapper.modal-wrapper--clickable-outside {
  pointer-events: none !important;
}

.modal-wrapper.modal-wrapper--show {
  animation: fade 0.5s ease-in-out;
  opacity: 1;
  pointer-events: all;
}

@keyframes fade {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.modal-close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -30px;
  right: 25px;
  mask: url(/src/assets/icons/icono_cierre_menu_neg.svg) center center / 20px 20px no-repeat ;
  background: var(--color1);
  border: none;
  outline: none;
}

.modal-content {
  position: relative;
  top: 140px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFFFFF;
  padding: 0 25px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  pointer-events: visible;
}
.modal-content.modal-content--50w {
  max-width: 50% !important;
}

.modal-content__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.modal-content__title h1 {
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 700;
  font-size: 3.0rem;
  order: -1;
  flex-grow: 1;
}

.modal-content__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.modal-content__body form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.modal-content p {
  color: rgba(33, 37, 41, 0.5);
  margin-bottom: 40px;
}

.modal-content button {
  cursor: pointer;
}

.modal-content__body button {
  align-self: center;
  border: none;
  background-color: var(--color1);
  display: flex;
  flex: 1;
  color: #FFFFFF;
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 900;
  font-size: 2.0rem;
  border-radius: 100px;
  margin: 0;
  padding: 20px;
  height: 65px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.modal-buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 17px;
}

.modal--right {
  background: none;
}

.modal--right .modal-content {
  background-color: var(--color1);
  color: #FFFFFF;
  height: 100%;
  max-width: 350px;
  margin: 0;
  border-radius: 0;
  align-self: flex-end;
  padding: 0;
}

.modal--right .modal-content form button {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 24px 0;
  border: none;
  border-radius: 0;
  color:#FFFFFF;
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 900;
  font-size: 1.8rem;
  background-color: var(--color2);
}

.modal--right .modal-content .modal-close {
  background-size: cover;
  width: 11px;
  height: 11px;
  top: 30px;
  right: 25px;
}

.modal--right .modal-content .modal-content__title {
  margin: 85px 30px 40px;
}

.modal--right .modal-content .modal-content__title h1 {
  font-size: 2.0rem;
}

.modal--right .modal-content .modal-content__body {
  padding: 0 40px;
  margin-bottom: 70px;
}

.modal--right .modal-content .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.modal--right .modal-content .form-group__title {
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.75);
  padding-bottom: 9px;
  margin-bottom: 10px;
  border-bottom: 1px solid #FFFFFF;
}

.modal--right .modal-content .form__checkbox-text {
  color: #FFFFFF;
  margin-left: 1rem;
}

.modal--right .modal-content .checkbox-container, .modal--right .checkbox {
  height: 1.3rem;
  width: 1.3rem;
}

.modal--right .modal-content .checkbox {
  border-color: #FFFFFF;
  border-radius: 4px;
}

.modal-content--large {
  min-height: 381px;
}

@media screen and (min-width: 62rem) {
  .modal-wrapper {
    z-index: 1200;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .modal-wrapper.modal--right {
    background: none;
  }

  .modal-content {
    top: 0;
    border-radius: 20px;
    padding: 40px 55px 58px 55px;
    max-width: 482px;
  }

  .modal-close {
    position: absolute;
    top: -40px;
    right: 0;
    mask: none;
    background: url(/src/assets/icons/icono_cierre_menu_neg.svg) center center / 20px 20px no-repeat ;
  }

  .modal-buttons {
    flex-direction: row;
  }
}