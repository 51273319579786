::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: lightgray;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: lightgray;
}

.char-count {
  margin-top: 10px;
  width: 100%;
  text-align: right;
}

.newproject__container {
  background-color: #F4F6F7;
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
}

.file-upload__preview {
  position: relative;
  width: 180px;
  height: 180px;
  background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
.file-upload__preview__btn-close {
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  bottom: 10;
  right: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-upload__preview__btn-close img {
  width: 12px !important;
  height: 12px !important;
}

.file-upload__preview img, .file-upload__preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.newproject__title {
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 700;
  font-size: 3.6rem;
  margin: 40px 0 45px;
  text-align: center;
}

.newproject__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: auto;
}

.newproject__form .form-group {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-bottom: 20px;
}

.newproject__form .form-card {
  background-color: #FFF;
  border-radius: 10px;
  padding: 32px 20px 30px;
  position: relative;
  width: 100%;
}

.form-card.form-card--file-upload {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.newproject__form .form-card__footnote{
  font-family: Karla, Roboto, sans-serif;
  font-style: italic;
  font-size: 1.6rem;
  color: #212529;
}

.newproject__form .form-control {
  border-radius: 10px;
  padding: 0 40px;
  color: #212529;
  width: 100%;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.8rem;
}

.newproject__form textarea.form-control  {
  min-height: 144px;
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 20px 40px;
}

.newproject__form .form__label__text {
  top: 21px;
  left: 50px;
  font-size: 1.8rem !important;
  font-family: Karla, Roboto, sans-serif !important;
  color: #00145e4d;
}

.newproject__form .form-button {
  text-transform: uppercase;
  font-weight: 900;
  background-color: var(--color2);
  margin: 30px 0 105px;
  padding: 0 40px;
}

.newproject__form .form-info {
  display: none;
  gap: 25px;
}

.newproject__form .form-info__title {
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 700;
  font-size: 2.4rem;
  color: var(--color1)
}
.newproject__form .form-info__description {
  font-family: Karla, Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  color: #212529;
}

.newproject__form .form-error {
  display: block;
  margin: 15px 10px 0;
  color: #FF0000;
  font-size: 1.6rem;
}

@media screen and (min-width: 62rem) {
  .newproject__container {
    padding: 0 80px;
  }

  .newproject__title {
    font-size: 6rem;
  }

  .newproject__form .form-group {
    column-gap: 50px;
    margin-bottom: 50px;
    justify-content: flex-end;
  }

  .newproject__form .form-info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 33.333%;
  }

  .newproject__form .form-card, .newproject__form .form-card__footnote {
    width: calc(66.666% - 50px);
  }

  .newproject__form .form-button {
    align-self: flex-end;
    width: fit-content;
    margin: 0 0 100px;
  }
}

@media screen and (min-width: 82rem) {
  .newproject__container {
    padding: 0 130px;
  }
}

@media screen and (min-width: 100rem) {
  .newproject__container {
    padding-left: 275px;
    padding-right: 275px;
  }
}