.login-remember-password {
  font-family: Karla, Roboto, sans-serif; 
  font-size: 1.2rem;
  margin-top: -5px;
  color: var(--color2);
  width: 100%;
  margin-bottom: 35px;
}
.login-remember-password.login-remember-password--lessmargin {
  margin-bottom: 25px;
}
.login-remember-password a {
  color: var(--color2);
  text-align: left;
  text-decoration: none;
  font-size: 1.2rem;
}

.form-button--login {
  margin-bottom: 25px;
}

.form__label__error-message--login {
  position: static;
  margin-top: -15px;
  margin-bottom: 25px;
  transition: fade-in .5s;
  height: 4rem;
  font-size: 1.4rem;
  font-style: Karla, Roboto, sans-serif;  
  vertical-align: middle;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



@media screen and (min-width: 62rem /* desktop 992px */) {
  .form__label__error-message--login {
    margin-top: -10px;
  }
  .login-remember-password.login-remember-password--lessmargin {
    margin-bottom: 20px;
  }
}