.saved-projects-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: wrap;
}
.savedcrowfundings-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.9rem;
  width: 100%;
  padding-top: 40px; 
}
.savedcrowfundings-wrapper img {
  max-width: 90px;
  
}
.savedcrowfundings-wrapper__text {
  font-size: 2rem;
  font-weight: 700;
  color: #6e6e6e;
}

@media screen and (min-width: 62rem) {

}

@media screen and (min-width: 82rem) {

}