.home-cards-wrapper {
  margin-right: -25px;
}

.home-footer-slidercards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.left-col-home {
  margin-bottom: 35px;
}

@media screen and (min-width: 62rem) {
  .home-footer-slidercards {
    flex-direction: row;
  }
  .home-cards-title__arrows {
    display: flex;
  }
  .home-cards-title > button {
    margin-right: auto;
    margin-left: 30px;
  }

  .left-col-home {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}