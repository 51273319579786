div.panel-container.help {
  background-image: url(/src/assets/icons/ilustracion_ayuda.svg);
  background-size: 426px 242px;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.help-text {
  font-family: Karla, Roboto, sans-serif;
  color: #212529;
  font-size: 1.8rem;
  margin-bottom: 30px;
}

.help-mail {
  font-family: Karla, Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--color1);
  text-decoration: none;
  width: fit-content;
}

@media screen and (min-width: 62rem) {
  div.panel-container.help {
    background-position: calc(100% + 200px) 100%;
    background-size: 809px 460px;
  }

  div.panel-container.help div.page-container {
    min-height: 600px;
  }
}

@media screen and (min-width: 82rem) {
  div.panel-container.help {
    background-position: 100% 100%;
    background-size: 450px 256px;
  }
}