.history-columns {
  display: none;
  flex-direction: row;
  font-family: Karla, Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  color: #212529;
  margin: 0 20px 25px;
  gap: 40px;
}

.history-columns > div {
  display: flex;
  flex: none;
}

.history-row {
  display: flex;
  flex-direction: column;
  font-family: Karla, Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  color: rgba(33, 37, 41, 0.5);
  padding: 25px 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.history-row:last-child {
  border: none;
}

.history-row button {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
}

.history-row span {
  color: #212529;
  font-weight: 700;
  font-size: 1.6rem;
}

.history-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history-container > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all .3s ease-in-out;
}

.history-container > div > div {
  display: flex;
}

.history-container > div:first-child > div {
  flex: 1 0 auto;
  overflow: hidden;
}

.history-row > .history-container > div:last-child {
  height: 0;
  opacity: 0;
}

.history-row.active > .history-container > div:last-child {
  height: 61px;
  opacity: 1;
}

.history-container > div:last-child > div {
  flex-direction: column;
  flex: 1 0 auto;
  gap: 10;
}

.history-name {
  order: 1;
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between;
  column-gap: 50px !important;
}

.history-row button.history-arrow {
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  background: url('/src/assets/icons/perfil_desplegable_down_smartphone.svg') center center no-repeat;
}

.history-row.active button.history-arrow {
  background-image: url('/src/assets/icons/perfil_desplegable_up_smartphone.svg');
}

.history-date {
  order: 2;
  width: 100%;
  height: 0;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.history-row.active .history-date {
  margin: 10px 0 30px;
  height: 22px;
  opacity: 1;
}

.history-receipt button {
  font-family: Karla, Roboto, sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  color: var(--color1);
}

.history-donation {
  flex: 1 auto !important;
}

.history-receipt {
  flex: 1 1 !important;
}


@media screen and (min-width: 62rem) {

}

@media screen and (min-width: 82rem) {
  .history-columns {
    display: flex;
  }

  .history-columns > div:first-child {
    width: 140px;
  }

  .history-columns > div:nth-of-type(2) {
    flex: 1;
  }

  .history-columns > div:nth-of-type(3) {
    width: 65px;
  }

  .history-columns > div:last-child {
    width: 150px;
  }

  .history-row span {
    display: none;
  }

  .history-container {
    flex-direction: row;
    gap: 40 !important;
  }

  .history-container > div {
    flex-flow: nowrap;
    flex: none;
    gap: 40 !important;
    align-items: center;
    transition: none;
  }

  .history-container > div > div {
    flex: none !important;
  }

  .history-row > .history-container > div:first-child {
    margin: 0 !important;
    flex: 1;
  }

  .history-row > .history-container > div:last-child {
    height: auto;
    opacity: 1;
  }

  .history-container > div > .history-name {
    order: 2;
    flex: 1 !important;
  }

  .history-row button.history-arrow {
    display: none;
  }

  .history-row.active .history-date {
    margin: 0;
  }

  .history-date {
    order: 1;
    width: 140px;
    height: auto;
    opacity: 1;
    transition: none;
  }

  .history-donation {
    width: 65px;
  }

  .history-receipt {
    width: 150px;
  }
}