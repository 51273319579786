.form-control[type="text"], .form-control[type="password"], .form-control[type="email"] {
  border: 1px solid #E5E5E5 !important;
  color: #9d9d9d !important;
}

.form-control[type="text"]:-ms-input-placeholder, .form-control[type="password"]:-ms-input-placeholder, .form-control[type="email"]:-ms-input-placeholder {
  color: #212529;
}

.form-control[type="text"]:focus, .form-control[type="password"]:focus, .form-control[type="email"]:focus {
  border: 2px solid var(--color2) !important;
  outline: none;
}

.font-color-error {
  display: block;
  margin-top: 20px;
  font-family: Karla, Roboto, sans-serif;
  font-size: 1.6rem;
  color: #FF0000;
}

.is-invalid {
  border: 1px solid #FF0000 !important;
}

.account-data__row {
  display: flex;
  row-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  font-family: nunito-sans, sans-serif;
  font-size: 1.6rem;
  color: #212529;
  padding: 23px 0;
  border-bottom: 1px solid #E5E5E5;
}

.account-data__row:last-child {
  border-bottom: none;
}

.account-data__row > div {
  display: flex;
  flex: 1;
}

.account-data__row > div:last-child {
  justify-content: end;
}

.account-data__row-header {
  flex: 0 1 auto !important;
  width: 100%;
  color: rgba(33, 37, 41, 0.5);
}

.account-data__avatar {
  background: var(--color2);
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.account-data__avatar img {
  height: 100%;
  width: 100%;
}

/* check if is it svg */
.account-data__avatar img.svg-loaded {
  height: 30px;
  width: 30px;
}

.avatar-image {
  background-color: #000;
  width: 110px;
  height: 110px;
  border: none;
  border-radius: 100%;
  align-self: center;
  margin-top: 20px;
}

.avatar-image img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.account-data__row button {
  cursor: pointer;
  height: 20px;
  width: 20px;
  font-family: Karla, Roboto, sans-serif;
  font-weight: 700;
  font-size: 0;
  mask: url(/src/assets/icons/perfil_editar_campo_smartphone.svg) center center no-repeat;
  -webkit-mask: url(/src/assets/icons/perfil_editar_campo_smartphone.svg) center center no-repeat;
  background-color: var(--color1);
  border: none;
}

.color-container {
  padding: 28px 20px;
  align-items: center;
  row-gap: 30px;
}

.colors {
  grid-gap: 25px;
}

.color-picker__container {
  width: auto;
}

.color-picker--bigger {
  outline: 4px solid rgba(0, 0, 0, 0.9) !important;
  outline-offset: -2px;
}

.color-name {
  font-family: Karla, Roboto, sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  color: rgba(33, 37, 41, 0.25);
}

.account-delete {
  font-family: Karla, Roboto, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  color: rgba(33, 37, 41, 0.5);
  padding: 32px 20px;
  gap: 15px;
  justify-content: space-between;
}

.account-delete button {
  font-family: Karla, Roboto, sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  color: var(--color1);
  background: none;
  border: none;
  display: flex;
  white-space: nowrap;
  cursor: pointer;
}

@media screen and (min-width: 62rem) {
  .account-data__row-header {
    flex: 1 !important;
    width: auto;
  }

  .account-data__avatar {
    width: 35px;
    height: 35px;
    margin-top: 0;
  }

  .account-data__avatar img.svg-loaded {
    height: 17px;
    width: 17px;
  }

  .account-data__row button {
    height: auto;
    width: auto;
    font-size: 1.8rem;
    color: var(--color1);
    mask: initial;
    -webkit-mask: initial;
    background-color: transparent
  }

  .color-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .color-container .color-picker {
    height: 30px;
    width: 30px;
  }

  .colors {
    grid-template-rows: repeat(2, 1fr) !important;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-gap: 15px;
  }

  .account-delete {
    flex-direction: row;
    align-items: center;
    column-gap: 25px;
  }
}

@media screen and (min-width: 82rem) {
  .colors {
    grid-template-rows: repeat(1, 1fr) !important;
    grid-template-columns: repeat(8, 1fr) !important;
  }
}