.footer.footer--color {
  background-color: var(--color1);
}

.footer.footer--blue {
  background-color: #39ADC1;
}

.footer.footer--border {
  border-top: 1px solid #FFFFFF;
}

.footer span {
  color: #FFFFFF;
  padding-top: 25px;
  padding-bottom: 25px;
  display: block;
  text-align: center;
  font: normal normal normal 16px/21px Karla;
}

@media screen and (min-width: 62rem) {
  .footer {
    padding: 0 139px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .footer-separator {
    border: none;
    display: flex;
    gap: 30px;
  }
}

@media screen and (min-width: 100rem) {
  .footer.footer--blue {
    padding-left: calc(190px + 5%);
    padding-right: calc(190px + 5%);
  }
}

@media screen and (min-width: 110rem) {
  .footer.footer--blue {
    padding-left: calc(190px + 10%);
    padding-right: calc(190px + 10%);
  }
}
